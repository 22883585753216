<template>
  <div>
    <div>
      <section
        class="mb-4 d-flex header-section"
        :class="{ invisible: loading.getSite }"
      >
        <Header
          :title="siteName"
          :subscription="subName"
          :subscriptionData="siteData ? siteData.subscription : ''"
        >
          <template #tabs>
            <v-tabs v-model="tab">
              <v-tab
                v-for="(tab, index) in tabs"
                @click="handleTabClick(tab)"
                :key="index"
                :disabled="!subName.includes('Swarm') && tab.label === 'Swarm'"
                class="no-background"
                data-ga="site_dashboard_tab"
                :data-cy="`site-v-tabs_${tab.label}`"
              >
                {{ tab.label }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                data-ga="icon_site_dashboard_force_refresh"
                @click="forceRefresh()"
                class="mr-2 icon-hover"
                >mdi-refresh</v-icon
              >
            </template>
            <span>Refresh</span>
          </v-tooltip>
          <div id="upload-dialog" class="" v-if="showDownload">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadReport()"
                  :disabled="exportDisabled"
                  class="h100 icon-hover"
                  data-ga="site_dashboard_export_report"
                  >mdi-download</v-icon
                >
              </template>
              <span>Export report</span>
            </v-tooltip>
            <div class="report-spinner">
              <v-progress-circular
                :size="30"
                color="gray"
                indeterminate
                v-if="reportLoading"
              ></v-progress-circular>
            </div>
          </div>
          <div class="dialog-container">
            <v-menu
              hide-overlay
              v-model="openMenu"
              attach="#upload-dialog"
              absolute
              :position-x="x"
              :position-y="y"
              min-width="300px"
              z-index="25"
              rounded
            >
              <v-card
                dense
                color="green"
                class="success-card d-flex justify-space-between"
              >
                <v-card-text class="white--text">
                  <p>
                    Success! When completed, the report will be emailed to
                    {{ displayEmail }}.
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-icon class="pointer" color="white"
                    >mdi-close-circle-outline</v-icon
                  >
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
        </Header>
      </section>
      <v-tabs-items v-model="tab" class="no-background">
        <!-- PULSE tab -->
        <v-tab-item :transition="false">
          <section>
            <SiteInfo
              :summary="siteSummary"
              :openIssueTurbineCount="openIssueTurbineCount"
              :turbineCount="turbineCount"
              :turbineModels="turbineModels"
              :loading="loading.getSite || loading.getTurbinesBySiteId"
              :hasWriteAccess="hasWriteAccess"
              :powerPriceLoading="powerPriceLoading"
              @editPowerPrice="sendPowerPriceChanges"
              @goToIssuesTable="goToIssuesTab"
              @goToChecksTab="goToChecksTab"
            />
          </section>
          <section class="mt-7 upload-anchor">
            <div class="d-flex">
              <v-tabs v-model="subTab" class="mb-4">
                <v-tab
                  v-for="(subTabItem, index) in dashboardTabs"
                  @click="handleTabClick(subTabItem)"
                  :key="index"
                  class="no-background"
                  data-ga="site_dashboard_tab"
                  data-cy="site-v-subtabs"
                >
                  <div></div>
                  {{ subTabItem.label }}
                </v-tab>
              </v-tabs>
              <div class="switch-wrapper" v-if="subTab !== 4 && showMapSwitch">
                <SwitchButton
                  label="Show map"
                  color="primary"
                  @changeVal="changeShowMap"
                />
              </div>
            </div>
            <v-row
              :class="{
                'mb-5': !isPowerCurveExplorerOpen && !isEventsViewOpen,
              }"
            >
              <v-col :lg="tableColumn" class="pb-0">
                <v-tabs-items
                  v-model="subTab"
                  class="no-background"
                  v-if="true"
                >
                  <v-tab-item
                    class="checks-tab"
                    data-cy="site-v-tab"
                    v-for="subTabItem in dashboardTabs"
                    :key="subTabItem.value"
                    :transition="false"
                  >
                    <div
                      :class="{
                        'dynamic-tabs-container':
                          !isPowerCurveExplorerOpen && !isEventsViewOpen,
                      }"
                    >
                      <template v-if="subTabItem.value === 'checks'">
                        <KeepAlive exclude="DataTable">
                          <ChecksTable
                            v-if="dashboardTabs[subTab].value === 'checks'"
                            :siteId="siteData.id"
                            :data="checks"
                            :loading="loading.getChecks"
                            sortBy="aep_loss_farm_pct_open"
                            :sortDesc="true"
                            :itemsPerPage="10"
                            :userHasInternalAccess="isInternal"
                            @goToIssuesTable="goToIssuesTab"
                            @getChecksTableData="callForChecks"
                          />
                        </KeepAlive>
                      </template>
                      <template v-if="subTabItem.value === 'turbines'">
                        <TurbineTable
                          v-if="dashboardTabs[subTab].value === 'turbines'"
                          :data="turbinesData"
                          :loading="loading.getTurbinesBySiteId"
                          :isSwarm="isSwarm"
                          sortBy="aep_loss_pct"
                          :sortDesc="true"
                          :itemsPerPage="10"
                          @goToIssuesTable="goToIssuesTab"
                        />
                      </template>
                      <template v-if="subTabItem.value === 'issues'">
                        <IssueTable
                          ref="issuesTable"
                          v-if="dashboardTabs[subTab].value === 'issues'"
                          :data="paginatedIssuesForIssuesTable"
                          :totalItems="paginatedIssuesTotal"
                          :loading="loading.getPaginatedIssuesForIssuesTable"
                          :footerProps="{
                            'items-per-page-options': [5, 10, 15, 25, 50],
                          }"
                          @issueDetailLoading="setIssueDetailLoading"
                          @paginationUpdated="getNewItemsSet"
                        />
                      </template>
                      <template v-if="subTabItem.value === 'documents'">
                        <div class="documents-tab">
                          <DocumentTable
                            v-if="dashboardTabs[subTab].value === 'documents'"
                            :siteLoading="loading.getDocumentData"
                            @setShowDialog="toggleUploadDialog"
                            @setShowViewer="openDocViewer"
                          />
                          <UploadDialog
                            v-if="showDialog"
                            title="Upload site document"
                            @setShowDialog="toggleUploadDialog"
                            @refreshDocuments="getDocumentData"
                          />
                          <div
                            @click="toggleUploadDialog(false)"
                            class="backdrop"
                            v-if="showDialog"
                          ></div>
                          <div
                            class="d-flex success-dialog"
                            v-if="successDialog"
                          >
                            <p class="success-dialog__text">
                              Success! Your file has been uploaded.
                            </p>
                          </div>
                          <div>
                            <div>
                              <div
                                @click="closeViewer"
                                class="backdrop"
                                v-if="showViewer"
                              ></div>
                              <div class="viewer-dialog" v-if="showViewer">
                                <DocumentViewer
                                  :documentData="documentObj"
                                  :totalPages="pdfTotalPages"
                                  :currentPage="pdfCurrentPage"
                                  :docPresent="showViewer"
                                  :loading="viewerLoading"
                                  @closeViewer="closeViewer"
                                  @pageUp="setPageUp"
                                  @pageDown="setPageDown"
                                  @goToPage="selectPage"
                                >
                                  <PDFPage v-if="pdfPresent">
                                    <div
                                      id="canvas_container"
                                      class="canvas-container"
                                    >
                                      <canvas id="the-canvas"></canvas>
                                    </div>
                                  </PDFPage>
                                </DocumentViewer>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template
                        v-if="subTabItem.value === 'data'"
                        data-cy="site-v-tab-data"
                      >
                        <div
                          class="site-data-tab"
                          data-cy="uploads-table-wrapper"
                        >
                          <SiteDataUploadsTable
                            v-if="dashboardTabs[subTab].value === 'data'"
                            :hasWriteAccess="hasWriteAccess"
                            :loading="loading.getSiteRawdata"
                            :dataUploaderType="dataUploaderType"
                            @setShowDialog="openUploadDialog"
                            @callForSiteDataUploads="callForSiteDataUploads"
                            @updateDataUploaderType="updateDataUploaderType"
                          />
                          <!-- Dialog for uploading site data -->
                          <UploadDialog
                            v-if="openDataUpload"
                            :isSiteDataUpload="openDataUpload"
                            @setShowDialog="openUploadDialog"
                            @postRawSiteData="postRawSiteData"
                          />
                          <div
                            class="backdrop"
                            v-if="openDataUpload"
                            @click="openDataUpload = false"
                          ></div>
                          <!-- CRUD table only editable by internal admin users, otherwise, read-only -->
                          <div class="data-table mt-5 mb-5">
                            <DITestTable
                              :testStatusData="diTestStatusData"
                              :lastUpdated="diTableLastUpdated"
                              :isInternalAdmin="isInternalAdmin"
                              :loading="diTestDataLoading"
                              @callForDITestStatus="callForDITestStatus"
                              @updateDITestStatus="updateDITestStatus"
                            />
                          </div>
                        </div>
                      </template>
                      <template
                        v-if="
                          subTabItem.value === 'site_analytics' &&
                          !isPowerCurveExplorerOpen &&
                          !isEventsViewOpen
                        "
                      >
                        <AnalyticsView
                          ref="analyticsView"
                          :turbineData="turbinesData"
                          :showMap="showMap"
                          @setStaticViewRequest="setAnalyticsViewRequest"
                          @openPowerCurveExplorer="togglePowerCurveExplorer"
                          @openEventsView="toggleEventsView"
                          @viewChanged="updateAnalyticsViewSelected"
                        />
                      </template>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
              <v-col
                sm="12"
                md="12"
                :lg="mapColumn"
                class="pb-0"
                v-if="subTab !== 5"
              >
                <div class="map-switch-anchor" v-if="showMap && subTab !== 4">
                  <MapView
                    :mapData="mapConfig"
                    :isMapLoading="loading.getTurbinesBySiteId"
                  />
                </div>
                <!-- Status cards for data tab -->
                <div id="status-metrics" v-else-if="subTab === 4">
                  <div
                    v-for="item in dataStatusCards"
                    :key="item.title"
                    class="metrics-cards"
                  >
                    <MetricsCards
                      :dataStatus="dataStatusCards"
                      :title="item.title"
                      :stalenessLevel="item.staleness_level"
                      :body="item.body"
                      :isWindescoFlag="
                        user.userData.email.includes('@windesco')
                      "
                      :loading="loading.getDataStatus"
                      v-if="subTab === 4"
                      @toggleSignalsMenu="toggleSignalsMenu"
                    />
                  </div>
                  <div v-if="user.userData.email.includes('@windesco')">
                    <SignalsMenu
                      :isSignalsMenuOpen="isSignalsMenuOpen"
                      :title="signalsMenuTitle"
                      :signals="availableSignals"
                      :signalDefs="signalDefs"
                      :signalsType="signalsMenuType"
                      :isInternal="isInternal"
                      :loading="signalsLoading"
                      @closeSignalsMenu="closeSignalsMenu"
                      @updateSignalsList="updateSignalsList"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
            <div v-if="subTab === 5 && isPowerCurveExplorerOpen">
              <PowerCurveExplorer
                :targetTurbineOptions="targetTurbineOptions"
                :loadingTurbines="loading.getTurbinesBySiteId"
                :showMap="showMap"
                @openPowerCurveExplorer="togglePowerCurveExplorer"
                @viewChanged="updateAnalyticsViewSelected"
              />
            </div>
            <div v-if="subTab === 5 && isEventsViewOpen" class="mt-4 mb-5">
              <EventsExplorer
                page="siteDashboard"
                @openEventsView="toggleEventsView"
                @viewChanged="updateAnalyticsViewSelected"
              />
            </div>
          </section>
        </v-tab-item>
        <!-- Swarm tab -->
        <v-tab-item :transition="false">
          <Swarm
            :siteId="+$route.params.siteId"
            :mapData="mapConfig"
            :refresh="refreshSwarm"
            :swarmTab="swarmTab"
            @tabClicked="currentSwarmTab"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import * as axiosApi from "@/helpers/axiosAPI";
import { mapActions, mapState } from "vuex";
import axios from "axios";
import SiteInfo from "@/components/SiteInfo";
import MetricsCards from "@/components/SummaryCards/MetricsCards";
import IssueTable from "@/components/IssueTable";
import TurbineTable from "@/components/TurbineTable";
import ChecksTable from "@/components/ChecksTable";
import DocumentTable from "@/components/DocumentTable";
import SiteDataUploadsTable from "@/components/SiteDataUploadsTable";
import UploadDialog from "@/components/UploadDialog";
import DocumentViewer from "@/components/DocumentViewer";
import SwitchButton from "@/components/SwitchButton";
import Header from "@/components/Header";
import MapView from "@/components/MapView";
import {
  mapTileLayerUrl,
  darkMapTileLayerUrl,
  clickCountLimit,
} from "@/helpers/variables";
import {
  findFileExt,
  setUrlParams,
  getDashboardTabs,
} from "@/helpers/functions";
import PDFPagination from "@/helpers/pdfPagination";
import PDFPage from "@/components/PDFPage";
import Swarm from "@/components/Swarm";
import AnalyticsView from "@/components/AnalyticsView";
import PowerCurveExplorer from "@/components/PowerCurveComponents/PowerCurveExplorer";
import EventsExplorer from "@/components/NewSiteDashboardComponents/EventsExplorer";
import DITestTable from "@/components/DITestTable";
import SignalsMenu from "@/components/SignalsMenu";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";
import {
  getAvailableSignals,
  getSignalDefinitions,
  updateAvailableSignals,
} from "@/helpers/availableSignalsAPIs";
import { getDITestStatus, updateDITestStatus } from "@/helpers/DITestTableAPIs";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  name: "SiteDashboard",
  components: {
    MapView,
    IssueTable,
    TurbineTable,
    SiteInfo,
    ChecksTable,
    DocumentTable,
    SiteDataUploadsTable,
    UploadDialog,
    DocumentViewer,
    SwitchButton,
    Header,
    PDFPage,
    Swarm,
    AnalyticsView,
    PowerCurveExplorer,
    EventsExplorer,
    MetricsCards,
    DITestTable,
    SignalsMenu,
  },
  data() {
    return {
      selected: "Month",
      showMap: true,
      subscription: "",
      summaryData: {},
      issueDetailLoading: false,
      tabs: [
        { label: "Pulse", value: "pulse" },
        { label: "Swarm", value: "swarm" },
      ],
      tab: 0,
      subTab: 0,
      subTabs: [
        { label: "Checks", value: "checks" },
        { label: "Turbines", value: "turbines" },
        { label: "Issues", value: "issues" },
        { label: "Documents", value: "documents" },
        { label: "Data", value: "data" },
        { label: "Analytics", value: "site_analytics" },
      ],
      swarmTab: "",
      currentSubTab: "issues",
      showDialog: false,
      showViewer: false,
      viewerLoading: false,
      documentObj: {},
      pdfPresent: false,
      pdfTotalPages: 0,
      pdfCurrentPage: 1,
      pdfEvent: {},
      findFileExt,
      displayEmail: "",
      x: 8,
      y: 8,
      reportLoading: false,
      openMenu: false,
      exportError: null,
      refreshSwarm: false,
      powerPriceLoading: false,
      openDataUpload: false,
      popupLoading: false,
      dashboardTabs: [],
      leftmostTab: "",
      staticAnalyticsViewRequest: {},
      turbineTableData: [],
      pageLoading: false,
      setUrlParams,
      is1550orLess: false,
      dataUploaderType: "User",
      loadingStarted: false,
      fromCheckDetail: false,
      isPowerCurveExplorerOpen: false,
      isEventsViewOpen: false,
      analyticsViewSelection: "yaw_misalignment",
      showMapSwitch: true,
      isSignalsMenuOpen: false,
      signalsMenuType: "",
      signalsMenuTitle: "",
      signalsLoading: false,
      availableHFSignals: [],
      available10mSignals: [],
      signalDefinitions: [],
      diTestStatusData: [],
      diTableLastUpdated: {},
      diTestDataLoading: false,
    };
  },
  computed: {
    ...mapState({
      siteData: (state) => state.site.siteData,
      statusCards: (state) => state.site.statusCards,
      siteSummary: (state) => state.site.siteSummary,
      subscriptionName: (state) => state.site.subscriptionName,
      turbinesData: (state) => state.site.turbinesData,
      checks: (state) => state.site.checks,
      paginatedIssuesForIssuesTable: (state) =>
        state.site.paginatedIssuesForIssuesTable,
      paginatedIssuesTotal: (state) => state.site.paginatedIssuesTotal,
      showSuccessDialog: (state) => state.site.successDialog,
      documents: (state) => state.site.documentData,
      uploadNotification: (state) => state.notifications.uploadNotification,
      user: (state) => state.user,
      isFromSwarm: (state) => state.swarm.isFromSwarm,
      siteRawdata: (state) => state.site.siteRawdata,
      clickCount: (state) => state.app.clickCount,
      loading: (state) => state.site.loading,
    }),
    tableColumn() {
      const url = new URL(window.location.href);
      if (
        (this.showMap && this.subTab < 4 && this.subTab !== 0) ||
        (this.subTab === 4 && !this.is1550orLess)
      ) {
        return 9;
      } else if (
        (!this.showMap && this.subTab !== 4 && this.subTab !== 0) ||
        this.is1550orLess ||
        this.subTab === 5 ||
        url.hash === "#site_analytics"
      ) {
        return 12;
      } else {
        return 9;
      }
    },
    mapColumn() {
      if (this.showMap && this.subTab !== 4 && this.subTab !== 0) {
        return 3;
      } else if (
        (this.showMap && this.subTab !== 4 && this.subTab !== 0) ||
        this.is1550orLess
      ) {
        return 12;
      } else {
        return 3;
      }
    },
    turbineModels() {
      const models = this.turbinesData?.map((a) => a.turbine_model);
      let itemCounts = {};
      models?.forEach((item) => {
        if (itemCounts[item]) {
          itemCounts[item]++;
        } else {
          itemCounts[item] = 1;
        }
      });

      let result = [];
      for (const [value, count] of Object.entries(itemCounts)) {
        result.push({ count, value });
      }

      return result;
    },
    notificationAndDocuments() {
      if (Object.keys(this.uploadNotification)?.length > 0) {
        const notificationObj = {
          subTab: this.uploadNotification.subTab,
          siteId: this.uploadNotification.siteId,
        };
        if (this.documents?.length > 0) {
          notificationObj.fileId = this.uploadNotification.fileId;
          notificationObj.documents = this.documents;
        }
        return notificationObj;
      } else {
        return null;
      }
    },
    openIssueTurbineCount() {
      if (this.siteData && Object.keys(this.siteData).length > 0) {
        return this.siteData.issues_summary?.open.turbine_ids.length;
      } else {
        return null;
      }
    },
    turbineCount() {
      if (this.turbineTableData.length > 0) {
        return this.turbineTableData.length;
      } else {
        return null;
      }
    },
    successDialog() {
      return this.showSuccessDialog;
    },
    showDownload() {
      if (Object.keys(this.siteData)?.length > 0) {
        return this.siteData.user_has_write_access;
      } else {
        return false;
      }
    },
    exportDisabled() {
      if (this.reportLoading || this.displayEmail) {
        return true;
      } else {
        return false;
      }
    },
    mapConfig() {
      let mapObj = {
        zoom: 12,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
      };
      if (this.turbinesData?.length > 0) {
        // eslint-disable-next-line no-undef
        const clonedTurbines = structuredClone(this.turbinesData);
        mapObj.markers = clonedTurbines;
      }
      return mapObj;
    },
    hasWriteAccess() {
      const siteId = this.$route.params.siteId;
      if (
        this.siteData &&
        "id" in this.siteData &&
        this.siteData.id == siteId &&
        this.siteData.user_has_write_access
      ) {
        return true;
      } else {
        return false;
      }
    },
    isSwarm() {
      if (this.isFromSwarm || this.tab === 1) {
        return true;
      } else {
        return false;
      }
    },
    /* Is internal and admin */
    isInternalAdmin() {
      if (this.user.userData.is_internal && this.user.userData.is_admin) {
        return true;
      }
      return false;
    },
    isInternal() {
      if (this.user.userData.is_internal) {
        return true;
      }
      return false;
    },
    subName() {
      if (this.subscriptionName) {
        return this.subscriptionName;
      } else {
        return "";
      }
    },
    siteName() {
      if (this.siteData?.name) {
        return this.siteData.name;
      } else {
        return "";
      }
    },
    dataStatusCards() {
      if (this.statusCards?.length > 0) {
        return this.statusCards;
      } else {
        // Returning empty array of cards to maintain cards with spinner while loading data
        return [
          {
            title: "HF data",
            body: [""],
          },
          { title: "10 minute data", body: [""] },
          { title: "Event data", body: [""] },
        ];
      }
    },
    targetTurbineOptions() {
      if (this.turbinesData.length > 0) {
        const options = this.turbinesData.map((turbine) => ({
          id: turbine.id,
          text: turbine.turbine_name,
          value: turbine.id,
          position: turbine.position,
          site_id: turbine.site_id,
          turbine_model: turbine.turbine_model,
          mapInfoTitle: turbine.mapInfoTitle,
          color: "grey",
        }));
        return options;
      } else {
        return [];
      }
    },
    availableSignals() {
      if (
        this.signalsMenuType.toLowerCase() === "hf" &&
        this.availableHFSignals.length > 0
      ) {
        return this.availableHFSignals;
      } else if (
        this.signalsMenuType.toLowerCase() === "10m" &&
        this.available10mSignals.length > 0
      ) {
        return this.available10mSignals;
      } else {
        return [];
      }
    },
    signalDefs() {
      if (this.signalDefinitions.length > 0) {
        return this.signalDefinitions;
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions({
      getPaginatedIssuesForIssuesTable: "site/getPaginatedIssuesForIssuesTable",
      setUploadNotification: "notifications/saveUploadNotification",
      setPowerPriceChanges: "site/editPowerPrice",
      updateIsFromSwarm: "swarm/updateIsFromSwarm",
      getSiteRawdata: "site/getSiteRawdata",
      postSiteRawdata: "site/postSiteRawdata",
      clearAnalytics: "site/clearAnalytics",
      getSite: "site/getSite",
      getTurbinesBySiteId: "site/getTurbinesBySiteId",
      getChecks: "site/getChecks",
      getDocuments: "site/getDocumentData",
      getDataStatus: "site/getDataStatus",
      incrementClickCount: "app/incrementClickCount",
      clearSiteData: "site/resetSite",
      handleAxiosError: "error/raiseError",
    }),
    callInitialAPIs() {
      const siteId = +this.$route.params.siteId;
      if (
        Object.keys(this.siteData)?.length === 0 ||
        this.siteData?.id !== siteId ||
        (this.siteData?.id === siteId && this.siteData?.issues_summary == null)
      ) {
        this.getSite({
          siteId,
          issuesSummary: 1,
        });
        this.clearAnalytics();
      }
      if (
        siteId !== this.turbinesData[0]?.site_id ||
        this.turbinesData?.length === 0
      ) {
        this.getTurbinesBySiteId(siteId);
      }
      // Calling for documents in initial calls to support notification navigation
      if (
        !this.documents ||
        (this.documents?.length > 0 && this.documents[0]?.id !== siteId) ||
        this.documents?.length === 0
      ) {
        this.getDocumentData();
      }
    },
    goToTab(tabValue) {
      let hashName = tabValue;
      if (tabValue?.includes("#")) {
        hashName = tabValue.split("#")[1];
      }
      const tabIndex = this.tabs.findIndex((x) => x.value === hashName);
      const subTabIndex = this.dashboardTabs.findIndex(
        (x) => x.value === hashName,
      );

      if (tabIndex > -1) {
        this.tab = tabIndex;
      }
      if (subTabIndex > -1) {
        this.subTab = subTabIndex;
      }
      if (hashName?.includes("swarm")) {
        this.tab = 1;
        this.swarmTab = hashName;
      }
      // If tab is checks
      if ((this.subTab === 0 && this.checks?.length === 0) || !hashName) {
        this.callForChecks("goToTab");
      }
    },
    handleTabClick(tab) {
      const url = new URL(window.location.href);
      let newUrl = null;
      let initialParams = {
        items_per_page: 10,
        page: 1,
        sort_desc: 1,
      };
      // Clear all url params before assigning
      url.search = "";
      url.hash = "";
      history.replaceState(null, null, url);
      if (tab.value === "pulse") {
        url.hash = this.subTabs[this.subTab].value;
      } else if (tab.value === "swarm") {
        url.hash = "swarm";
        initialParams = {};
        history.replaceState(null, null, url);
        return;
      } else {
        url.hash = tab.value;
      }
      if (tab.value === "pulse" || this.tab === 0) {
        let index = null;
        let hashName = "";
        this.updateIsFromSwarm(false);
        if (tab?.value === "pulse") {
          url.hash = this.dashboardTabs[this.subTab].value;
          hashName = this.dashboardTabs[this.subTab].value;
        } else if (tab === 0) {
          url.hash = this.dashboardTabs[0].value;
          hashName = this.dashboardTabs[0].value;
        } else {
          if (typeof tab === "number") {
            index = tab;
          } else {
            index = this.dashboardTabs.findIndex(
              (dTab) => dTab.value === tab?.value,
            );
          }

          url.hash = this.dashboardTabs[index].value;
          hashName = this.dashboardTabs[index].value;
        }

        switch (hashName) {
          case "turbines":
            initialParams.sort_by = "issues_that_need_attention";
            break;
          case "issues":
            if (this.user?.userData?.is_internal) {
              this.selectedStatusFilters = [
                "unconfirmed",
                "needs_review",
                "new",
                "in_progress",
              ];
            } else {
              this.selectedStatusFilters = ["new", "in_progress"];
            }
            initialParams.sort_by = "aep_loss_pct";
            break;
          case "checks":
            initialParams.sort_by = "aep_loss_farm_pct_open";
            break;
          case "documents":
            initialParams = {};
            initialParams.page = 1;
            initialParams.items_per_page = 10;
            break;
          case "data":
            initialParams.sort_by = "upload_start";
            initialParams.items_per_page = 5;
            break;
          case "site_analytics":
            initialParams = {};
            if (!this.isPowerCurveExplorerOpen && !this.isEventsViewOpen) {
              // Set the url params according to the static object
              if (Object.keys(this.staticAnalyticsViewRequest).length > 0) {
                initialParams.view = this.staticAnalyticsViewRequest.view;
                initialParams.start_date =
                  this.staticAnalyticsViewRequest.start_date;
                initialParams.end_date =
                  this.staticAnalyticsViewRequest.end_date;
              }
            } else if (this.isPowerCurveExplorerOpen) {
              initialParams.view = "power_curve_explorer";
            } else if (this.isEventsViewOpen) {
              initialParams.view = "events";
            }
            break;
          case "swarm":
            initialParams = {};
            break;
        }
        newUrl = setUrlParams(url, initialParams);

        if (url.hash === "#issues" && tab.value !== "swarm") {
          let defaultStatuses = ["new", "in_progress"];
          if (this.user?.userData?.is_internal) {
            defaultStatuses = [
              "unconfirmed",
              "needs_review",
              "new",
              "in_progress",
            ];
          }
          for (const status of defaultStatuses) {
            newUrl.searchParams.append("include_status", status);
          }
          if (newUrl.searchParams.get("sort_by") !== "aep_loss_pct") {
            newUrl.searchParams.set("sort_by", "aep_loss_pct");
          }
        }
        // Set the turbine id url params for site analytics with the url search params directly
        // since we can not append identical property names to a regular object
        if (
          url.hash === "#site_analytics" &&
          this.tab === 0 &&
          !this.isPowerCurveExplorerOpen &&
          !this.isEventsViewOpen
        ) {
          newUrl.search = "";
          if (Object.keys(this.staticAnalyticsViewRequest).length > 0) {
            newUrl.searchParams.append(
              "view",
              this.staticAnalyticsViewRequest.view,
            );
            if (this.staticAnalyticsViewRequest.start_date) {
              newUrl.searchParams.append(
                "start_date",
                this.staticAnalyticsViewRequest.start_date,
              );
            }
            if (this.staticAnalyticsViewRequest.end_date) {
              newUrl.searchParams.append(
                "end_date",
                this.staticAnalyticsViewRequest.end_date,
              );
            }
          }
          if (this.staticAnalyticsViewRequest.turbine_ids?.length > 0) {
            for (const turbineId of this.staticAnalyticsViewRequest
              .turbine_ids) {
              if (turbineId !== "all_turbines") {
                newUrl.searchParams.append("turbine_id", turbineId);
              }
            }
          }
        }
      } else {
        // Making sure to initalize url for keep alive checks table
        if (
          (tab.value === "checks" || this.subTab === 0) &&
          url.hash !== "checks"
        ) {
          url.hash = "checks";
          initialParams.sort_by = "aep_loss_farm_pct_open";
          newUrl = setUrlParams(url, initialParams);
          this.callForChecks("tabClicked");
        } else {
          url.hash = tab.value;
        }
      }

      if (url.hash !== "#site_analytics" && url.hash !== "#data") {
        this.showMapSwitch = true;
      } else if (url.hash === "#site_analytics") {
        if (
          this.analyticsViewSelection !== "power_curve_explorer" &&
          this.analyticsViewSelection !== "events"
        ) {
          this.showMapSwitch = false;
        }
      }

      history.replaceState(null, null, newUrl ? newUrl : url);
    },
    callForChecks(type) {
      const url = new URL(window.location.href);
      const siteId = +this.$route.params.siteId;
      if (this.tab === 1 || this.isFromSwarm) {
        return;
      }
      // Separate conditionals by type and checks length
      if (
        this.checks?.length > 0 &&
        !this.loading.getChecks &&
        (type === "refresh" || this.checks[0]?.site_id !== siteId)
      ) {
        this.getChecks({ siteId });
        return;
      }
      if (
        this.checks?.length === 0 &&
        !this.loading.getChecks &&
        (url.hash === "#checks" ||
          (!url.hash && this.subTab === 0) ||
          type === "tabClicked")
      ) {
        this.getChecks({ siteId });
        return;
      }
    },
    callForSiteDataUploads(refresh) {
      const siteId = +this.$route.params.siteId;
      if (
        this.siteRawdata.length === 0 ||
        this.siteRawdata[0].site_id !== siteId ||
        refresh
      ) {
        const uploaderType = this.dataUploaderType.toLowerCase();
        this.getSiteRawdata({ siteId, uploaderType });
      }
      if (
        this.statusCards.length === 0 ||
        (this.statusCards.length > 0 &&
          this.statusCards[0].site_id !== siteId) ||
        refresh
      ) {
        this.getDataStatus(siteId);
      }
    },
    changeShowMap(value) {
      this.showMap = value;
      const eventLabel = `site_map_toggle_${value}`;
      // Track filter selections
      if (this.clickCount < clickCountLimit) {
        // Data sent to Google Analytics
        if (eventLabel) {
          this.incrementClickCount();
          gaTrackEvent(this.$gtag, {
            eventName: "first_clicks_after_login",
            eventCategory: "user_interaction",
            eventLabel: eventLabel.toLowerCase(),
            value: this.clickCount,
          });
        }
      }
    },
    async getNewItemsSet(payload) {
      const siteId = +this.$route.params.siteId;
      // Wait a tick to allow the goToTab method to set the correct sub tab value on refresh
      await null;
      if (this.dashboardTabs[this.subTab].value !== "issues") {
        return;
      }
      await this.getPaginatedIssuesForIssuesTable({
        siteId,
        ...payload,
      });
    },
    getDocumentData() {
      const siteId = +this.$route.params.siteId;
      if (siteId) {
        this.getDocuments(siteId);
      }
    },
    setIssueDetailLoading(loading) {
      this.issueDetailLoading = loading;
    },
    toggleUploadDialog(showDialog) {
      this.showDialog = showDialog;
    },
    openUploadDialog(showDataUpload) {
      this.openDataUpload = showDataUpload;
    },
    /**
     * Open document viewer for file previews
     */
    openDocViewer(item) {
      const fileExt = findFileExt(item.uri);
      const html = document.querySelector("html");
      html.style.overflow = "hidden";
      this.documentObj.name = item.name;
      this.documentObj.type = fileExt;
      this.documentObj.uri = item.uri;

      if (this.documentObj.type === "pdf") {
        this.viewerLoading = true;
        // eslint-disable-next-line no-undef
        this.getPdf(item.uri, pdfjsLib);
      } else if (this.documentObj.type === "html") {
        this.viewerLoading = true;
        axios.get(item.uri).then((response) => {
          this.documentObj.data = response.data;
          this.showViewer = true;
          this.viewerLoading = false;
        });
      } else {
        this.showViewer = true;
        this.viewerLoading = false;
      }
    },
    async getPdf(url, pdfjsLib) {
      const pdf = await PDFPagination.loadPdf(url, pdfjsLib);
      this.pdfTotalPages = pdf.numPages;
      this.pdfEvent = pdf;
      this.getPdfPage(1);
      this.pdfPresent = true;
      this.showViewer = true;
      this.viewerLoading = false;
    },
    // Find a way to make sure this is resolved before calling it again to avoid pages not loading if click through too fast
    async getPdfPage(page) {
      await PDFPagination.loadPdfPage(page, this.pdfEvent);
    },
    async setPageUp() {
      const page = PDFPagination.pageUp(
        this.pdfTotalPages,
        this.pdfCurrentPage,
      );
      this.pdfCurrentPage = page;
      await this.getPdfPage(page);
    },
    async setPageDown() {
      const page = PDFPagination.pageDown(
        this.pdfTotalPages,
        this.pdfCurrentPage,
      );
      this.pdfCurrentPage = page;
      await this.getPdfPage(page);
    },
    async selectPage(page) {
      const pageSelected = PDFPagination.pageSelection(
        page,
        this.pdfTotalPages,
        this.pdfCurrentPage,
      );
      this.pdfCurrentPage = page;
      await this.getPdfPage(pageSelected);
    },
    closeViewer() {
      this.showViewer = false;
      this.pdfPresent = false;
      this.pdfCurrentPage = 1;
      const html = document.querySelector("html");
      html.style.overflow = "auto";
      this.setUploadNotification({});
    },
    downloadReport() {
      this.reportLoading = true;
      axiosApi
        .default({
          url: `/sites/${this.siteData.id}/report`,
          method: "GET",
        })
        .then((response) => {
          if (response.status === 200) {
            this.displayEmail = response.data.email;
            this.openMenu = true;
          }
          setTimeout(() => {
            this.displayEmail = "";
            this.openMenu = false;
          }, 4000);
          this.reportLoading = false;
        })
        .catch((error) => {
          if (error) {
            this.exportError = error;
          }
        });
    },
    async sendPowerPriceChanges(payload) {
      this.powerPriceLoading = true;
      await this.setPowerPriceChanges(payload);
      this.powerPriceLoading = false;
    },
    closeAlert() {
      this.displayEmail = "";
    },
    goToIssuesTab(type, source, name) {
      for (const tab of this.dashboardTabs) {
        if (tab.value === "issues") {
          this.subTab = this.dashboardTabs.indexOf(tab);
          break;
        }
      }
      // Set issue status filters
      if (type && source) {
        const url = new URL(window.location.href);
        let tempUrl = null;
        let newUrl = null;
        url.search = "";
        let params = {
          include_statuses: [],
          page: 1,
          items_per_page: 10,
          sort_by: "aep_loss_pct",
          sort_desc: 1,
        };
        if (type === "open") {
          params.include_statuses = ["new", "in_progress"];
        } else if (type === "fixed") {
          params.include_statuses = ["fixed"];
        } else if (type === "unconfirmed") {
          params.include_statuses = ["unconfirmed", "needs_review"];
        }
        if (name) {
          params.search_for = name;
        }
        // Call method inside issues table to set filters inside issues table
        if (url.hash === "#issues" && source === "summary") {
          this.$refs["issuesTable"][0].changeStatusFilters(
            params.include_statuses,
          );
        }
        tempUrl = this.setUrlParams(url, params, "include_status");
        url.hash = "issues";
        if (tempUrl) {
          newUrl = tempUrl;
        } else {
          newUrl = url;
        }
        history.replaceState(null, null, newUrl);
      } else {
        this.handleTabClick(this.subTab);
      }
    },
    goToChecksTab() {
      for (const tab of this.dashboardTabs) {
        if (tab.value === "checks") {
          this.subTab = this.dashboardTabs.indexOf(tab);
          break;
        }
      }
      this.handleTabClick(this.subTab);
    },
    async postRawSiteData(payload) {
      await this.postSiteRawdata(payload);
    },
    currentSwarmTab(tabValue) {
      this.swarmTab = tabValue;
    },
    confirmPageRefresh(event) {
      const rawDataUploading = this.siteRawdata.filter(
        (upload) => upload.uploading,
      );

      if (rawDataUploading.length > 0) {
        event.preventDefault();
        return (event.returnValue = "");
      }
    },
    setAnalyticsViewRequest(request) {
      // Request details for setting url params on navigation to site analytics tab
      this.staticAnalyticsViewRequest.site_id = request.site_id;
      this.staticAnalyticsViewRequest.start_date = request.start_date;
      this.staticAnalyticsViewRequest.end_date = request.end_date;
      this.staticAnalyticsViewRequest.turbine_ids = request.turbine_ids;
      this.staticAnalyticsViewRequest.view = request.view;
    },
    togglePowerCurveExplorer(isOpen) {
      this.isPowerCurveExplorerOpen = isOpen;
    },
    toggleEventsView(isOpen) {
      this.isEventsViewOpen = isOpen;
    },
    checkScreenSize() {
      this.is1550orLess = window.innerWidth <= 1550;
    },
    updateAnalyticsViewSelected(view) {
      this.analyticsViewSelection = view;
      if (view === "power_curve_explorer") {
        this.togglePowerCurveExplorer(true);
        this.showMapSwitch = true;
      } else {
        this.showMapSwitch = false;
      }
    },
    updateDataUploaderType(value) {
      this.dataUploaderType = value;
      this.callForSiteDataUploads(true);
    },
    async callForDITestStatus() {
      this.diTestDataLoading = true;
      const siteId = +this.$route.params.siteId;
      const response = await getDITestStatus({ siteId });
      this.diTestStatusData = response.status_table;
      if (response?.updated_ts && response?.updated_user_email) {
        const splitDate = response.updated_ts.split("T");
        const updatedTs = `${splitDate[0]} ${splitDate[1]}`;
        this.diTableLastUpdated = {
          updatedBy: response.updated_user_email,
          updatedTs: updatedTs,
        };
      }
      this.diTestDataLoading = false;
    },
    async updateDITestStatus(payload) {
      this.diTestDataLoading = true;
      const siteId = +this.$route.params.siteId;
      const params = {
        site_id: siteId,
        status_table: payload,
      };
      const response = await updateDITestStatus(params);
      this.diTestStatusData = response.status_table;
      const splitDate = response.updated_ts.split("T");
      const updatedTs = `${splitDate[0]} ${splitDate[1]}`;
      this.diTableLastUpdated = {
        updatedBy: response.updated_user_email,
        updatedTs: updatedTs,
      };
      this.diTestDataLoading = false;
    },
    setSignalsType(title) {
      if (title.toLowerCase().includes("high frequency")) {
        return "HF";
      } else {
        return "10m";
      }
    },
    async toggleSignalsMenu(type, title) {
      const siteId = +this.$route.params.siteId;
      this.signalsMenuTitle = title;
      let params = {
        site_id: siteId,
      };
      // Close the dialog if the current title does not match the current kind
      if (title.toLowerCase().includes("high frequency")) {
        if (this.isSignalsMenuOpen && this.signalsMenuType === "10m") {
          this.isSignalsMenuOpen = false;
        }
        this.signalsMenuType = "HF";
        params.kind = "hf";
      } else if (title.includes("10 minute")) {
        if (this.isSignalsMenuOpen && this.signalsMenuType === "HF") {
          this.isSignalsMenuOpen = false;
        }
        this.signalsMenuType = "10m";
        params.kind = "10m";
      }
      // Toggle the menu open or closed
      this.isSignalsMenuOpen = !this.isSignalsMenuOpen;
      // Call for signal definitions
      if (
        this.signalDefinitions.length === 0 ||
        this.signalDefinitions[0]?.kind !== params.kind ||
        this.signalDefinitions.siteId !== siteId
      ) {
        this.signalsLoading = true;
        this.signalDefinitions = await getSignalDefinitions(params);
      }
      // Call for available signals
      if (
        (params.kind === "hf" &&
          (this.availableHFSignals.length === 0 ||
            this.availableHFSignals[0]?.siteId !== siteId)) ||
        (params.kind === "10m" &&
          (this.available10mSignals.length === 0 ||
            this.available10mSignals[0]?.siteId !== siteId))
      ) {
        this.signalsLoading = true;
        if (params.kind === "hf") {
          this.availableHFSignals = await getAvailableSignals(params);
        } else if (params.kind === "10m") {
          this.available10mSignals = await getAvailableSignals(params);
        }
      }
      this.signalsLoading = false;
    },
    async updateSignalsList(payload) {
      this.signalsLoading = true;
      if (payload.kind === "hf") {
        this.availableHFSignals = await updateAvailableSignals(payload);
      } else if (payload.kind === "10m") {
        this.available10mSignals = await updateAvailableSignals(payload);
      }
      this.signalsLoading = false;
    },
    closeSignalsMenu() {
      this.isSignalsMenuOpen = false;
    },
    async forceRefresh() {
      const siteId = +this.$route.params.siteId;
      // If tabbed to PULSE
      if (this.tab === 0) {
        this.getSite({
          siteId,
          issuesSummary: 1,
          refresh: true,
        });
        this.getTurbinesBySiteId(siteId);
        this.getDocumentData();
        if (this.siteRawdata.length > 0 || this.statusCards.length > 0) {
          this.callForSiteDataUploads("refresh");
        }
        if (this.checks.length > 0) {
          this.callForChecks("refresh");
        }
        this.clearAnalytics();
      } else {
        // If tabbed to Swarm
        this.refreshSwarm = true;
        setTimeout(() => {
          this.refreshSwarm = false;
        }, 500);
      }
    },
  },
  created() {
    // Change this value to the desired leftmost tab
    this.leftmostTab = "checks";
    this.dashboardTabs = getDashboardTabs("sites", this.leftmostTab);
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.confirmPageRefresh);
    this.callInitialAPIs();
  },
  async mounted() {
    const url = new URL(window.location.href);
    if (url.hash) {
      this.goToTab(url.hash);
    } else {
      url.hash = this.leftmostTab;
      this.goToTab();
    }

    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);

    // Set worker src for pdf retrieval
    // eslint-disable-next-line no-undef
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.js";
  },
  beforeRouteUpdate(to, from, next) {
    if (this.tabs[this.tab].value === "swarm") {
      // Setting boolean for use in fully loaded component
      this.updateIsFromSwarm(true);
    }
    next();
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.confirmPageRefresh);
    window.removeEventListener("resize", this.checkScreenSize);
  },
  watch: {
    notificationAndDocuments: {
      deep: true,
      handler(value) {
        if (value) {
          const url = new URL(window.location.href);
          const { fileId, documents, subTab } = value;
          if (subTab) {
            url.hash = `#${subTab}`;
            for (const tab of this.dashboardTabs) {
              if (tab.value === subTab) {
                this.subTab = this.dashboardTabs.indexOf(tab);
              }
              if (fileId && documents.length > 0) {
                this.documents.forEach((doc) => {
                  if (doc.id === value.fileId) {
                    this.openDocViewer(doc);
                  }
                });
              }
            }
          }
          this.setUploadNotification({});
          history.replaceState(null, null, url);
        }
      },
    },
    openMenu: {
      handler(value) {
        if (!value) {
          this.displayEmail = "";
        }
      },
    },
    siteData: {
      immediate: true,
      handler(data) {
        const siteId = this.$route.params.siteId;
        const url = new URL(window.location.href);
        if (data && data.id == siteId) {
          if (data.subscription.name.includes("Swarm")) {
            url.search = "";
            url.hash = "swarm";
            this.tab = 1;
            this.goToTab("swarm");
          } else if (!url.hash) {
            this.updateIsFromSwarm(false);
            url.hash = "checks";
            this.goToTab("checks");
          } else {
            this.goToTab(url.hash);
          }
          history.replaceState(null, null, url);
        }
      },
    },
    "loading.getSite": {
      immediate: true,
      handler(value) {
        if (
          !value &&
          this.siteData &&
          Object.keys(this.siteData).length > 0 &&
          !this.siteData.subscription?.definition?.name
        ) {
          this.$router.push("/expired-subscription");
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.title-section {
  height: 5.25rem;
}

#upload-dialog {
  position: relative;
}

.report-spinner {
  display: block;
  position: absolute;
  z-index: 5;
}

.dialog-anchor {
  position: relative;
}

.dialog-container {
  position: relative;
}

.dialog {
  height: 500px;
}

.success-card p {
  margin: 0;
}

.filter-wrapper {
  width: 13rem;
}

::v-deep {
  .filter-wrapper {
    .v-input__control {
      .v-input__slot {
        background-color: transparent;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        font-family: "Museo Sans Rounded" !important;
        height: 2rem;

        .v-select__selection {
          line-height: 1;
        }
      }
    }
  }
}

.upload-anchor {
  position: relative;
}

.documents-tab {
  position: relative;
}

.success-dialog {
  position: absolute;
  top: 10%;
  left: 30%;
  width: 21rem;
  height: 3rem;
  font-size: 1.05rem;
  z-index: 2070;
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: #1c64ca;
  color: white;
  align-items: center;
  justify-content: center;
}

.success-dialog__text {
  margin: 0;
}

.upload-data-trigger {
  position: relative;
}

.header-section {
  position: relative;
}

.canvas-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.viewer-dialog {
  position: relative;
  z-index: 1500;
  width: 100%;
}

.checks-tab {
  position: relative;
  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 1000;
  }
}

#status-metrics {
  position: relative;
  margin-top: 0.75rem;
}

.metrics-cards {
  padding-bottom: 1rem;
}

.view-wrapper {
  width: 100%;
}

.divider {
  margin-top: 8rem;
}

@media (max-width: 1550px) {
  #status-metrics {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    .metrics-cards .metrics-card {
      min-height: 10rem;
    }
  }
}
</style>
<style>
@media (max-width: 1550px) {
  #status-metrics {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    .metrics-cards .metrics-card {
      min-height: 10rem;
    }
  }
}
</style>
