var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.serverDialog || _vm.edgeDialog)?_c('div',{staticClass:"backdrop",on:{"click":function($event){return _vm.cancel()}}}):_vm._e(),_c('div',{staticClass:"mb-4"},[(_vm.errorMessageServer)?_c('div',{staticClass:"error-alert__server"},[_c('v-alert',{attrs:{"type":"error","dismissible":"","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.errorMessageServer)+" ")])],1):_vm._e(),_c('DataTable',{attrs:{"className":"no-background deployments-table","items":_vm.serverDeploymentsTableData,"table":"server_deployments","loading":_vm.loading.postServerDeployment || _vm.loading.getServerDeployments,"itemKey":"id","isSwarm":true,"headers":_vm.mutableServerHeaders,"sortBy":"start_ts","sortDesc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"no-background toolbar mb-2",attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Server deployments")]),(_vm.isInternalAdmin)?_c('v-toolbar-items',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer ml-1 add-server-row__icon",attrs:{"size":"1.7rem"},on:{"click":function($event){return _vm.openAddRow('server')}}},'v-icon',attrs,false),on),[_vm._v("mdi-plus")])]}}],null,false,334105443)},[_c('span',[_vm._v("Create new deployment")])]),_c('EditDeploymentsTablesWidget',{attrs:{"serverDialog":_vm.serverDialog,"tableType":_vm.table,"turbines":_vm.turbines,"createDeploymentLoading":_vm.createDeploymentLoading},on:{"cancel":_vm.cancel,"addNewRow":_vm.addNewRow}})],1):_vm._e(),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"mr-3"},[_c('div',{staticClass:"d-flex align-center"},[_c('label',{staticClass:"mr-2 mt-3"},[_vm._v("Only show latest")]),_c('v-switch',{staticClass:"switch",attrs:{"dense":"","hide-details":""},model:{value:(_vm.showLatestServer),callback:function ($$v) {_vm.showLatestServer=$$v},expression:"showLatestServer"}})],1)]),_c('div',{staticClass:"column-filter-wrapper"},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"server-cog-icon"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog-outline")])]}}])},[_c('span',[_vm._v("Column filters")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.serverColFilterMenuOpen,"menuActivator":"#server-cog-icon","menuItems":_vm.serverColFilterMenuItems,"showAll":_vm.showAllServerCols,"offset":"y","filterType":"column","table":"server_deployments_table"},on:{"changeFilters":_vm.setServerTableColsToShow,"clearSelectedItems":_vm.clearServerSelectedColItems,"setSearchText":_vm.setServerColFilterSearchText,"clearFilterSearch":_vm.clearServerColFilterSearch}})],1)],1)]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,staticClass:"server-row"},[(_vm.checkColumn('start_ts', 'server'))?_c('td',[_c('span'),_vm._v(_vm._s(_vm.formatTimestamp(item.start_ts))+" ")]):_vm._e(),(_vm.checkColumn('end_ts', 'server'))?_c('td',[_vm._v(" "+_vm._s(_vm.formatTimestamp(item.end_ts))+" ")]):_vm._e(),(_vm.checkColumn('user_email', 'server'))?_c('td',[_vm._v(" "+_vm._s(item.user_email)+" ")]):_vm._e(),(_vm.checkColumn('expect_perf_change', 'server'))?_c('td',[_vm._v(" "+_vm._s(item.expect_perf_change ? "Yes" : "No")+" ")]):_vm._e(),(_vm.checkColumn('version', 'server'))?_c('td',[_vm._v(" "+_vm._s(item.version)+" ")]):_vm._e(),(_vm.checkColumn('values', 'server'))?_c('td',[_c('v-menu',{attrs:{"open-on-hover":"","open-delay":_vm.popupDelay,"bottom":"","offset-y":"","disabled":_vm.addingServerRow},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"monospace"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.shortValues(item.values))+" ")])]}}],null,true)},[_c('vue-code-highlight',{attrs:{"language":"javascript"}},[_c('div',[_c('pre',[_vm._v(_vm._s(item.values))])])])],1)],1):_vm._e(),(_vm.checkColumn('notes', 'server'))?_c('td',[_c('v-menu',{attrs:{"open-on-hover":"","open-delay":_vm.popupDelay,"max-width":"400px","bottom":"","offset-y":"","disabled":_vm.addingServerRow},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(" "+_vm._s(item.notes ? _vm.shortNotes(item.notes) : ""))])],1)]}}],null,true)},[_c('v-list',{staticClass:"notes-list"},[_c('v-list-item',[_c('v-list-item-content',[_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(" "+_vm._s(item.notes))])],1)],1)],1)],1)],1):_vm._e()])}),0)]}}])})],1),_c('div',{staticClass:"mt-4 edges-table"},[(_vm.errorMessageEdges)?_c('div',{staticClass:"error-alert__edges"},[_c('v-alert',{attrs:{"type":"error","dismissible":"","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.errorMessageEdges)+" ")])],1):_vm._e(),_c('DataTable',{attrs:{"loading":_vm.loading.postEdgeDeployment || _vm.loading.getEdgeDeployments,"items":_vm.edgeDeploymentsTableData,"table":"edge_deployments","isSwarm":true,"itemKey":"id","headers":_vm.mutableEdgeHeaders},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"no-background toolbar",attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Edge deployments")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-toolbar-items',[_c('v-icon',_vm._g(_vm._b({staticClass:"pointer ml-1 add-edges-row__icon",attrs:{"size":"1.7rem"},on:{"click":function($event){return _vm.openAddRow('edge')}}},'v-icon',attrs,false),on),[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Create new deployment")])]),_c('EditDeploymentsTablesWidget',{attrs:{"edgeDialog":_vm.edgeDialog,"tableType":_vm.table,"turbines":_vm.turbines,"createDeploymentLoading":_vm.createDeploymentLoading},on:{"cancel":_vm.cancel,"addNewRow":_vm.addNewRow}}),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"mr-3"},[_c('div',{staticClass:"d-flex align-center"},[_c('label',{staticClass:"mr-2 mt-3"},[_vm._v("Only show latest")]),_c('v-switch',{staticClass:"switch",attrs:{"dense":"","hide-details":"","disabled":_vm.latestEdgeDeployments.length === 0},model:{value:(_vm.showLatestEdges),callback:function ($$v) {_vm.showLatestEdges=$$v},expression:"showLatestEdges"}})],1)]),_c('div',{staticClass:"column-filter-wrapper"},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"edge-cog-icon"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog-outline")])]}}])},[_c('span',[_vm._v("Column filters")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.edgeColFilterMenuOpen,"menuActivator":"#edge-cog-icon","menuItems":_vm.edgeColFilterMenuItems,"showAll":_vm.showAllEdgeCols,"offset":"y","filterType":"column","table":"edge_deployments_table"},on:{"changeFilters":_vm.setEdgeTableColsToShow,"clearSelectedItems":_vm.clearEdgeSelectedColItems,"setSearchText":_vm.setEdgeColFilterSearchText,"clearFilterSearch":_vm.clearEdgeColFilterSearch}})],1)],1)]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[(_vm.checkColumn('turbine_name', 'edge'))?_c('td',[_vm._v(" "+_vm._s(item.turbine_name)+" ")]):_vm._e(),(_vm.checkColumn('end_ts', 'edge'))?_c('td',[_vm._v(" "+_vm._s(_vm.formatTimestamp(item.end_ts))+" ")]):_vm._e(),(_vm.checkColumn('user_email', 'edge'))?_c('td',[_vm._v(" "+_vm._s(item.user_email)+" ")]):_vm._e(),(_vm.checkColumn('version', 'edge'))?_c('td',[_vm._v(" "+_vm._s(item.version)+" ")]):_vm._e(),(_vm.checkColumn('config', 'edge'))?_c('td',[_c('v-menu',{attrs:{"open-on-hover":"","open-delay":_vm.popupDelay,"bottom":"","offset-y":"","disabled":_vm.addingEdgeRow},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"monospace"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.shortValues(item.config))+" ")])]}}],null,true)},[_c('vue-code-highlight',{attrs:{"language":"javascript"}},[_c('div',[_c('pre',[_vm._v(_vm._s(item.config))])])])],1)],1):_vm._e(),(_vm.checkColumn('notes', 'edge'))?_c('td',[_c('v-menu',{attrs:{"open-on-hover":"","open-delay":_vm.popupDelay,"max-width":"400px","bottom":"","offset-y":"","disabled":_vm.addingEdgeRow},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(" "+_vm._s(item.notes ? _vm.shortNotes(item.notes) : ""))])],1)]}}],null,true)},[_c('v-list',{staticClass:"notes-list"},[_c('v-list-item',[_c('v-list-item-content',[_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(" "+_vm._s(item.notes))])],1)],1)],1)],1)],1):_vm._e()])}),0)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }