<template>
  <div class="swarm">
    <div class="overlay" @click="handleClose" v-if="toggleSelect"></div>
    <DataLoading size="80" v-if="swarmInfoLoading" />
    <v-row v-else>
      <v-col cols="4" v-for="(widget, index) in widgetCards" :key="index">
        <SummaryCard
          :headerContent="widget.headerContent"
          :bodyContent="widget.bodyContent"
          :bodyPercent="widget.bodyPercent"
          :footerContent="widget.footerContent"
          :footerPercent="widget.footerPercent"
          :swarmAvailability="swarmAvailability"
          :class="{ 'total-gain': widget.headerContent === 'AEP gain' }"
        />
      </v-col>
    </v-row>
    <div class="d-flex">
      <v-tabs v-model="tab" class="my-2">
        <v-tab
          v-for="(tab, index) in tabs"
          @click="handleTabClick(tab)"
          :key="index"
          class="no-background"
          data-ga="swarm_tab"
        >
          {{ tab.label }}
          <div
            v-if="swarmAlerts.length > 0 && tab.value === 'swarm_alerts'"
            class="pointer alert-indicator ml-1"
          >
            {{ swarmAlerts.length }}
          </div>
        </v-tab>
      </v-tabs>
      <div class="swarm-map-switch d-flex align-center">
        <SwitchButton
          v-if="tab <= 2"
          :hideDetails="true"
          label="Show map"
          color="primary"
          @changeVal="toggleShowMap($event)"
        />
      </div>
    </div>
    <v-row>
      <v-col :xl="showMap ? 9 : 12">
        <v-tabs-items v-model="tab" class="no-background">
          <v-tab-item :transition="false">
            <DataTable
              className="no-background"
              itemKey="site_id"
              :headers="statusHeader"
              :items="statusData"
              table="swarm_server_status"
              :loading="loading.getSwarmStatus"
              :hideFooter="true"
              :item-class="rowClasses"
            >
              <template v-slot:top
                ><v-toolbar flat dense class="no-background"
                  ><v-toolbar-title
                    class="d-flex justify-space-between align-center w100 flex-wrap"
                    style="white-space: normal"
                    ><span>Swarm Server status</span>
                    <span
                      v-if="
                        swarmStatus &&
                        swarmStatus.server &&
                        swarmStatus.server.last_updated_ts
                      "
                      class="subtitle-2 font-weight-regular"
                      style="white-space: normal"
                      >Last updated: {{ lastUpdated }}</span
                    ></v-toolbar-title
                  ></v-toolbar
                >
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td v-if="typeof item.status != 'boolean'">
                      {{ item.status }}
                    </td>
                    <td v-if="item.status && typeof item.status === 'boolean'">
                      <v-icon style="color: #46c78f !important">
                        mdi-check
                      </v-icon>
                    </td>
                    <td v-if="!item.status && typeof item.status === 'boolean'">
                      <v-icon style="color: #de3617 !important">
                        mdi-close
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </DataTable>
          </v-tab-item>
          <v-tab-item :transition="false"
            ><DataTable
              className="no-background mt-6"
              itemKey="site_id"
              :headers="selectedEdgeHeaders"
              :items="edges"
              table="swarm_edges"
              emptyDataText="Swarm Edge status currently unavailable."
              :loading="loading.getSwarmStatus"
              :hideFooter="edges ? edges.length < 11 : true"
              :item-class="rowClasses"
              :search="searchText"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                  dense
                  class="no-background edge-toolbar"
                  style="z-index: 9"
                >
                  <div class="d-flex align-center">
                    <v-toolbar-title class="w100"
                      >Swarm Edge status</v-toolbar-title
                    >
                    <div class="ml-3">
                      <v-text-field
                        solo
                        dense
                        append-icon="mdi-magnify"
                        hide-details
                        label="Search..."
                        class="no-border edge-search"
                        @input="updateSearch"
                        clearable
                      ></v-text-field>
                    </div>
                  </div>
                  <v-select
                    :items="edgeHeader"
                    multiple
                    v-model="selectedEdgeHeaders"
                    :menu-props="{
                      value: toggleSelect,
                      maxHeight: 'fit-content',
                    }"
                    return-object
                  ></v-select>
                  <v-btn
                    small
                    icon
                    @click="toggleSelect = !toggleSelect"
                    :color="`${toggleSelect ? 'primary' : 'none'}`"
                  >
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:body="{ items }">
                <tbody v-if="edges && edges.length > 0">
                  <tr v-for="item in items" :key="item.id">
                    <td
                      v-if="
                        selectedEdgeHeaders.some(
                          (header) => header.value === 'turbine_name',
                        )
                      "
                    >
                      {{ item.turbine_name }}
                    </td>
                    <td
                      v-if="
                        selectedEdgeHeaders.some(
                          (header) => header.value === 'n_alarms',
                        )
                      "
                    >
                      <PopUp :content="item.alarm_names">
                        <template v-slot:hover>{{ item.n_alarms }}</template>
                        <template v-slot:content v-if="item.n_alarms > 0">
                          <div
                            v-for="(alarm, index) in item.alarm_names"
                            :key="index"
                          >
                            <div class="text-caption pa-3">
                              {{ alarm }}
                            </div>
                            <v-divider
                              style="margin: 0"
                              v-if="index < item.alarm_names.length - 1"
                            ></v-divider>
                          </div>
                        </template>
                      </PopUp>
                    </td>
                    <td
                      v-if="
                        selectedEdgeHeaders.some(
                          (header) => header.value === 'mode',
                        )
                      "
                    >
                      {{ item.mode }}
                    </td>
                    <td
                      v-if="
                        selectedEdgeHeaders.some(
                          (header) => header.value === 'state',
                        )
                      "
                    >
                      {{ item.state }}
                    </td>
                    <td
                      v-if="
                        item.controllable &&
                        selectedEdgeHeaders.some(
                          (header) => header.value === 'controllable',
                        )
                      "
                    >
                      <v-icon style="color: #46c78f !important">
                        mdi-check
                      </v-icon>
                    </td>
                    <td
                      v-if="
                        !item.controllable &&
                        selectedEdgeHeaders.some(
                          (header) => header.value === 'controllable',
                        )
                      "
                    >
                      <v-icon style="color: #de3617 !important">
                        mdi-close
                      </v-icon>
                    </td>
                    <td
                      :class="{
                        'error--text': item.latest_data_ts
                          ? new Date() - new Date(item.latest_data_ts) >
                            72000000 // Two hours in milliseconds
                          : false,
                      }"
                    >
                      {{ item.latest_data_ts }}
                    </td>
                    <td
                      v-if="
                        selectedEdgeHeaders.some(
                          (header) => header.value === 'edge_availability',
                        )
                      "
                    >
                      {{ roundToString(item.edge_availability, 1) }}
                    </td>
                    <td
                      v-if="
                        selectedEdgeHeaders.some(
                          (header) => header.value === 'windspeed_mps_mn',
                        )
                      "
                    >
                      {{ roundToString(item.windspeed_mps_mn, 1) }}
                    </td>
                    <td
                      v-if="
                        selectedEdgeHeaders.some(
                          (header) => header.value === 'realpower_kw_mn',
                        )
                      "
                    >
                      {{ roundToString(item.realpower_kw_mn, 1) }}
                    </td>
                    <td
                      v-if="
                        selectedEdgeHeaders.some(
                          (header) => header.value === 'wind_direction',
                        )
                      "
                    >
                      {{ roundToString(item.wind_direction, 1) }}
                    </td>
                    <td
                      v-if="
                        selectedEdgeHeaders.some(
                          (header) => header.value === 'nacdirestimate_deg_mn',
                        )
                      "
                    >
                      {{ roundToString(item.nacdirestimate_deg_mn, 1) }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td :colspan="6" style="text-align: center">
                      Swarm Edge status currently unavailable.
                    </td>
                  </tr>
                </tbody>
              </template>
            </DataTable>
          </v-tab-item>
          <v-tab-item :transition="false">
            <DataTable
              className="no-background alert-table"
              itemKey="site_id"
              :headers="alertHeader"
              :items="swarmAlerts"
              table="swarm_alerts"
              sort-by="ts"
              :sort-desc="true"
              emptyDataText="There are currently no alerts!"
              :loading="loading.getSwarmAlerts"
              :hideFooter="swarmAlerts ? swarmAlerts.length < 11 : true"
              ><template v-slot:top
                ><v-toolbar flat dense class="no-background"
                  ><v-toolbar-title>Alerts</v-toolbar-title></v-toolbar
                >
              </template>
            </DataTable>
          </v-tab-item>
          <v-tab-item :transition="false"
            ><v-col cols="12"><SwarmAnalytics /></v-col
          ></v-tab-item>
          <v-tab-item :transition="false">
            <v-col cols="12"
              ><SwarmDeployments
                :edges="edges"
                @getServerDeployments="getNewServerDeployments"
                @getEdgeDeployments="getNewEdgeDeployments"
            /></v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col v-if="showMap && tab <= 2" sm="12" md="12" lg="12" xl="3">
        <MapView :mapData="swarmMapData" :isMapLoading="swarmMapLoading" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import SwarmAnalytics from "@/components/SwarmAnalytics";
import SwarmDeployments from "@/components/SwarmDeployments";
import DataTable from "@/components/DataTable";
import MapView from "@/components/MapView";
import SwitchButton from "@/components/SwitchButton";
import SummaryCard from "@/components/SummaryCards/SummaryCard";
import DataLoading from "@/components/DataLoading";
import PopUp from "@/components/PopUp";
import dayjs from "dayjs";
import { roundToString } from "@/helpers/functions";
import { mapTileLayerUrl, darkMapTileLayerUrl } from "@/helpers/variables";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";
import { clickCountLimit } from "@/helpers/variables";

export default {
  name: "Swarm",
  components: {
    SwarmAnalytics,
    SwarmDeployments,
    DataTable,
    MapView,
    SwitchButton,
    SummaryCard,
    DataLoading,
    PopUp,
  },
  data() {
    return {
      alertHeader: [
        { text: "Timestamp (UTC)", value: "ts" },
        { text: "Details", value: "detail" },
        { text: "Turbine", value: "turbine_name" },
      ],
      edgeHeader: [
        { text: "Turbine", value: "turbine_name", order: 0 },
        { text: "Alarms", value: "n_alarms", order: 1 },
        { text: "Mode", value: "mode", order: 2 },
        { text: "State", value: "state", order: 3 },
        { text: "Controllable", value: "controllable", order: 4 },
        { text: "Latest data", value: "latest_data_ts", order: 5 },
        {
          text: "Avail. (7d, %)",
          value: "edge_availability",
          order: 6,
        },
        {
          text: "WS (m/s)",
          value: "windspeed_mps_mn",
          order: 7,
        },
        {
          text: "Power (kW)",
          value: "realpower_kw_mn",
          order: 8,
        },
        {
          text: "WD (deg)",
          value: "wind_direction",
          order: 9,
        },
        {
          text: "ND (deg)",
          value: "nacdirestimate_deg_mn",
          order: 10,
        },
      ],
      statusHeader: [
        { text: "Name", value: "name" },
        { text: "Status", value: "status" },
      ],
      statusData: [],
      selected: 0,
      tabs: [
        { label: "Server", value: "swarm_server" },
        { label: "Edges", value: "swarm_edges" },
        { label: "Alerts", value: "swarm_alerts" },
        { label: "Analytics", value: "swarm_analytics" },
        { label: "Deployments", value: "swarm_deployments" },
      ],
      tab: 0,
      showMap: true,
      swarmMapData: {
        zoom: 12,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
        markers: [],
      },
      widgetCards: [],
      pageLoading: true,
      toggleSelect: false,
      selectedEdgeHeaders: [],
      searchText: "",
      swarmInfoLoading: false,
      swarmMapLoading: false,
    };
  },
  props: {
    siteId: {
      type: Number,
      required: true,
      default: null,
    },
    mapData: {
      Object,
      required: true,
      default: () => {},
    },
    refresh: {
      type: Boolean,
      required: false,
      default: false,
    },
    swarmTab: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    ...mapState({
      swarmAlerts: (state) => state.swarm.swarmAlerts,
      swarmStatus: (state) => state.swarm.swarmStatus,
      swarmInfo: (state) => state.swarm.swarmInfo,
      userData: (state) => state.user.userData,
      loading: (state) => state.swarm.loading,
      turbinesData: (state) => state.sites.turbines,
      turbinesLoading: (state) => state.sites.loading.getTurbinesBySiteId,
      swarmAvailability: (state) => state.swarm.swarmAvailability,
      clickCount: (state) => state.app.clickCount,
    }),
    edges() {
      const edges = this.swarmStatus?.edges;
      if (edges && this.swarmInfoAndAvailability.swarmAvailability?.edges) {
        for (let i = 0; i < edges.length; i++) {
          const id = edges[i].turbine_id;
          const availability =
            this.swarmInfoAndAvailability.swarmAvailability.edges[id];
          edges[i].edge_availability = availability;
        }
      }
      return edges;
    },
    isInternalUser() {
      if (this.userData && Object.keys(this.userData).length > 0) {
        return this.userData.is_internal;
      } else {
        return false;
      }
    },
    lastUpdated() {
      return (
        dayjs(this.swarmStatus.server.last_updated_ts).format(
          "YYYY-MM-DD HH:mm:ss",
        ) + " UTC"
      );
    },
    swarmMapDataComputed() {
      return this.swarmMapData.markers;
    },
    loadingGetSwarmStatus() {
      return this.loading.getSwarmStatus;
    },
    swarmInfoAndAvailability() {
      return {
        swarmInfo: this.swarmInfo,
        swarmAvailability: this.swarmAvailability,
      };
    },
  },
  methods: {
    ...mapActions({
      getSwarmAlerts: "swarm/getSwarmAlerts",
      getSwarmStatus: "swarm/getSwarmStatus",
      getSwarmInfo: "swarm/getSwarmInfo",
      getServerDeployments: "swarm/getServerDeployments",
      getEdgeDeployments: "swarm/getEdgeDeployments",
      getTurbinesBySiteId: "sites/getTurbinesBySiteId",
      getSwarmAvailability: "swarm/getSwarmAvailability",
      incrementClickCount: "app/incrementClickCount",
    }),
    roundToString,

    handleClose() {
      this.toggleSelect = false;
    },
    handleTabClick(tab) {
      this.$router
        .replace({
          hash: tab.value,
          query: null,
        })
        .catch((err) => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location",
            )
          ) {
            console.error(err);
          }
        });
      this.$emit("tabClicked", tab.value);
    },
    rowClasses(item) {
      if (item.status === false || item.n_alarms > 0) {
        return "red-background";
      }
    },
    setStatusData() {
      this.statusData = [
        {
          name: "Control enabled",
          status: this.swarmStatus?.server?.control_enabled,
        },
        {
          name: "Cloud comms okay",
          status: this.swarmStatus?.server?.cloud_comms_okay,
        },
        {
          name: "Optimization running",
          status: this.swarmStatus?.server?.optimization_running,
        },
        {
          name: "SCADA comms okay",
          status: this.swarmStatus?.server?.scada_comms_okay,
        },
        {
          name: "Toggle state high",
          status: this.swarmStatus?.server?.toggle_state_high,
        },
        {
          name: "Toggle period",
          status: this.swarmStatus?.server?.toggle_period,
        },
        {
          name: "Software version",
          status: this.swarmStatus?.server?.sw_version,
        },
        {
          name: "Wake model ID",
          status: this.swarmStatus?.server?.wake_model_id,
        },
        {
          name: "Global TI",
          status: this.swarmStatus?.server?.global_ti
            ? roundToString(this.swarmStatus.server.global_ti * 100, 1) + "%"
            : null,
        },
      ];
    },
    setWidgetCards() {
      this.widgetCards = [];
      const availabilityObj = {
        edges: this.swarmAvailability?.edges_mean,
        server: this.swarmAvailability?.server,
      };
      const wsAvail = roundToString(
        this.swarmAvailability.wake_steering_pct,
        1,
      );
      this.widgetCards.push(
        {
          headerContent: "System availability (past 7d)",
          bodyContent: availabilityObj,
          bodyPercent: "",
          footerContent: `Wake steering: ${wsAvail}%`,
        },
        {
          headerContent: "Cumulative gains",
          bodyContent: `$${roundToString(
            this.swarmInfo.iep_upper_usd,
            -1,
          )} (${roundToString(this.swarmInfo.iep_mwh, -1)} MWh)`,
          bodyPercent: this.swarmInfo.iep_pct,
          footerContent: "",
        },
        {
          headerContent: "AEP gain",
          bodyContent: `${this.swarmInfo.iaep_pct}%`,
          bodyPercent: "",
          footerContent: `${roundToString(
            this.swarmInfo.iaep_mwhpyr,
            -1,
          )} MWh/yr`,
        },
      );
    },
    toggleShowMap(value) {
      this.showMap = value;
      const eventLabel = `swarm_map_toggle_${value}`;
      // Track filter selections
      if (this.clickCount < clickCountLimit) {
        // Data sent to Google Analytics
        if (eventLabel) {
          this.incrementClickCount();
          gaTrackEvent(this.$gtag, {
            eventName: "first_clicks_after_login",
            eventCategory: "user_interaction",
            eventLabel: eventLabel.toLowerCase(),
            value: this.clickCount,
          });
        }
      }
    },
    setSwarmMapData() {
      const edgesAndMarkers = [];
      for (const item of this.mapData?.markers) {
        const edges = this.swarmStatus?.edges?.find(
          (date) => date.turbine_id === item.id,
        );
        if (!edges) {
          edgesAndMarkers.push({
            ...item,
          });
        } else {
          edgesAndMarkers.push({
            ...item,
            edge: edges,
          });
        }
      }

      this.swarmMapData.markers = edgesAndMarkers.map((item) => ({
        id: item.id,
        site_id: item.site_id,
        turbine_name: item.turbine_name,
        turbine_model: item.turbine_model,
        color: item.edge
          ? item.edge.controllable
            ? "green"
            : !item.edge.controllable
              ? "red"
              : "gray"
          : "gray",
        n_alarms: item.edge ? item.edge.n_alarms : null,
        position: item.position,
        selected: false,
        mapInfoTitle: item.turbine_name,
        mapInfoRoute: `/turbines/${item.id}`,
      }));
    },
    async refreshData() {
      this.pageLoading = true;
      this.swarmInfoLoading = true;
      this.swarmMapLoading = true;
      let promises = [];
      // Call this outside the promises so that the page can load while status data may still be loading
      this.getSwarmStatus(this.$route.params.siteId);
      // Create an array of promises to call all APIs asynchronously
      promises = [
        this.getSwarmInfo(this.$route.params.siteId),
        this.getSwarmAlerts(this.$route.params.siteId),
        this.getSwarmAvailability({ siteId: this.$route.params.siteId }),
        this.getServerDeployments(this.$route.params.siteId),
        this.getEdgeDeployments(this.$route.params.siteId),
      ];
      if (
        this.turbinesData?.length === 0 ||
        this.$route.params.siteId !== this.turbinesData[0]?.farm_id
      ) {
        promises.push(
          this.getTurbinesBySiteId({
            siteId: this.$route.params.siteId,
          }),
        );
      }
      // Resolve all the promises returned by the swarm API calls
      await Promise.all(promises);
      this.setSwarmMapData();
    },
    updateSearch(e) {
      this.searchText = e;
    },
    async getNewServerDeployments() {
      this.pageLoading = true;
      await this.getServerDeployments(this.siteId);
      this.pageLoading = false;
    },
    async getNewEdgeDeployments() {
      this.pageLoading = true;
      await this.getEdgeDeployments(this.siteId);
      this.pageLoading = false;
    },
  },
  beforeMount() {
    this.refreshData();
    if (this.swarmTab === "swarm_edges") {
      this.tab = 1;
    }
    if (this.swarmTab === "swarm_alerts") {
      this.tab = 2;
    }
    if (this.swarmTab === "swarm_analytics") {
      this.tab = 3;
    }
    if (this.swarmTab === "swarm_deployments") {
      this.tab = 4;
    }
    this.selectedEdgeHeaders = this.edgeHeader;
  },
  watch: {
    refresh: {
      handler() {
        if (this.refresh) {
          this.refreshData();
        }
      },
      immediate: true,
    },
    loadingGetSwarmStatus: {
      handler(data) {
        if (!data) {
          this.setStatusData();
        }
      },
    },
    swarmMapDataComputed: {
      handler(data) {
        if (data.length > 0) {
          this.swarmMapLoading = false;
        }
      },
    },
    swarmInfoAndAvailability: {
      immediate: true,
      handler(data) {
        const { swarmInfo, swarmAvailability } = data;

        if (
          swarmInfo &&
          Object.keys(swarmInfo).length > 0 &&
          swarmAvailability &&
          Object.keys(swarmAvailability).length > 0
        ) {
          this.setWidgetCards();
          this.swarmInfoLoading = false;
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/variables";
.swarm .popup-container .popup {
  bottom: 0px;
  left: -200px;
  max-width: 275px;
  max-height: 150px;
  overflow-y: auto;
}
.swarm .overlay {
  position: absolute;
  z-index: 7;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.red-background {
  background-color: $red2;
}
.edge-toolbar .v-toolbar__content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.alert-table td:first-of-type {
  white-space: nowrap;
}
.swarm-map-switch {
  .v-input--selection-controls {
    margin-top: 0;
  }
  label {
    white-space: nowrap;
  }
}
.alert-indicator {
  width: 14px;
  line-height: 14px;
  border-radius: 50%;
  text-align: center;
  font-size: 8px;
  color: white;
  background-color: var(--v-error-base);
  z-index: 10;
}
</style>
<style lang="scss" scoped>
.v-select {
  opacity: 0;
  z-index: 100;
}
.edge-search {
  min-width: 200px;
}
.spinner-container {
  position: relative;
  height: 100px;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
  }
}
</style>
