<template>
  <div class="swarm-analytics">
    <div class="d-flex justify-center align-center">
      <v-select
        label="Chart type"
        v-model="selected"
        :items="chartType"
        hide-details
        class="mr-2"
        solo
        dense
      >
      </v-select>
      <date-picker type="date" v-model="startDate" placeholder="Start ts">
        <template v-slot:content="slotProps">
          <div class="d-flex">
            <calendar-panel
              v-model="startDate"
              :disabled-date="(date) => date >= new Date()"
              :value="slotProps.value"
              @select="slotProps.emit"
            ></calendar-panel>
          </div>
        </template>
      </date-picker>
      <date-picker type="date" v-model="endDate" placeholder="End ts">
        <template v-slot:content="slotProps">
          <div class="d-flex">
            <calendar-panel
              v-model="endDate"
              :disabled-date="
                (date) =>
                  date < new Date(dayjs(startDate).format('YYYY-MM-DD')) ||
                  date > new Date()
              "
              :value="slotProps.value"
              @select="slotProps.emit"
            ></calendar-panel>
          </div>
        </template>
      </date-picker>
      <v-btn
        @click="updatePlotly"
        :loading="loading"
        :disabled="
          selected === 'Edge state' && (startDate === null || endDate === null)
        "
        color="green"
        class="white--text mr-2"
        >Update</v-btn
      >
      <v-btn
        @click="downloadData"
        :loading="loading"
        :disabled="
          selected != 'Nacelle direction' ||
          nacDirDownloadUrl === null ||
          nacDirLoading
        "
        color="blue"
        class="white--text"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <label v-if="selected === 'Yaw misalignment'" class="ml-2 mr-2 mb-0"
        >Show adjustments</label
      >
      <v-switch
        v-if="selected === 'Yaw misalignment'"
        v-model="showYawMisalignmentAdjustments"
      />
    </div>
    <div class="d-flex justify-center mt-3">
      <div v-if="selected === 'Edge state'">
        <Plotly
          v-if="Object.keys(swarmEdgeTs).length > 0"
          id="plotly_diagram"
          :data="swarmEdgeTs.data"
          :layout="layout"
          :responsive="true"
          :display-mode-bar="false"
          :showTips="false"
        />
      </div>
      <section
        id="display"
        class="mb-5 mt-9 plotly-container"
        v-if="selected === 'Availability'"
      >
        <div class="analytics-display">
          <h3>Server</h3>
          <DataLoading v-if="availabilityLoading" class="analytics-loading" />
          <Plotly
            v-else-if="server"
            :data="server.data"
            :layout="server.layout"
            :display-mode-bar="false"
            :showTips="false"
          ></Plotly>
          <div class="no-data-backdrop" v-else>
            <div class="no-data-label d-flex justify-center">
              <span>No data available</span>
            </div>
          </div>
        </div>
        <div class="analytics-display">
          <h3>Edges</h3>
          <DataLoading v-if="availabilityLoading" class="analytics-loading" />
          <Plotly
            v-else-if="edges"
            :data="edges.data"
            :layout="edges.layout"
            :display-mode-bar="false"
            :showTips="false"
          ></Plotly>
          <div class="no-data-backdrop" v-else>
            <div class="no-data-label d-flex justify-center">
              <span>No data available</span>
            </div>
          </div>
        </div>
        <div class="analytics-display">
          <h3>Wake steering</h3>
          <DataLoading v-if="wsAvailabilityLoading" class="analytics-loading" />
          <Plotly
            v-else-if="wsAvailabilityFigure"
            :data="wsAvailabilityFigure.data"
            :layout="wsAvailabilityFigure.layout"
            :display-mode-bar="false"
            :showTips="false"
          ></Plotly>
          <div class="no-data-backdrop" v-else>
            <div class="no-data-label d-flex justify-center">
              <span>No data available</span>
            </div>
          </div>
        </div>
      </section>
      <section
        id="display"
        class="mb-5 mt-9 plotly-container"
        v-if="selected === 'Nacelle tracking'"
      >
        <div v-if="selected === 'Nacelle tracking'">
          <DataLoading
            v-if="nacTrackingErrorLoading"
            class="analytics-loading"
          />
          <Plotly
            v-if="nacTrackingErrorFigure && !nacTrackingErrorLoading"
            id="plotly_diagram"
            :data="nacTrackingErrorFigure.data"
            :layout="nacTrackingErrorFigure.layout"
            :responsive="true"
            :display-mode-bar="false"
            :showTips="false"
          ></Plotly>
        </div>
        <div v-if="selected === 'Nacelle tracking'">
          <DataLoading
            v-if="nacTrackingDelayLoading"
            class="analytics-loading"
          />
          <Plotly
            v-if="nacTrackingDelayFigure && !nacTrackingDelayLoading"
            id="plotly_diagram"
            :data="nacTrackingDelayFigure.data"
            :layout="nacTrackingDelayFigure.layout"
            :responsive="true"
            :display-mode-bar="false"
            :showTips="false"
          ></Plotly>
        </div>
      </section>
      <section
        id="display"
        class="mb-5 mt-9 plotly-container"
        v-if="selected === 'SCADA Latency'"
      >
        <div v-if="selected === 'SCADA Latency'">
          <DataLoading v-if="scadaLatencyLoading" class="analytics-loading" />
          <Plotly
            v-if="scadaLatencyFigure && !scadaLatencyLoading"
            id="plotly_diagram"
            :data="scadaLatencyFigure.data"
            :layout="scadaLatencyFigure.layout"
            :responsive="true"
            :display-mode-bar="false"
            :showTips="false"
          ></Plotly>
        </div>
      </section>
      <section
        id="display"
        class="mb-5 mt-9 plotly-container"
        v-if="selected === 'Nacelle direction'"
      >
        <div v-if="selected === 'Nacelle direction'">
          <DataLoading v-if="nacDirLoading" class="analytics-loading" />
          <Plotly
            v-else-if="nacDirFigures.mean_diff_bar && !nacDirLoading"
            id="plotly_diagram"
            :data="nacDirFigures.mean_diff_bar.data"
            :layout="nacDirFigures.mean_diff_bar.layout"
            :responsive="true"
            :display-mode-bar="false"
            :showTips="false"
          ></Plotly>
          <div class="no-data-backdrop" v-else>
            <div class="no-data-label d-flex justify-center">
              <span>No data available</span>
            </div>
          </div>
        </div>
        <div v-if="selected === 'Nacelle direction'">
          <DataLoading v-if="nacDirLoading" class="analytics-loading" />
          <Plotly
            v-if="nacDirFigures.diff_time_series && !nacDirLoading"
            id="plotly_diagram"
            :data="nacDirFigures.diff_time_series.data"
            :layout="nacDirFigures.diff_time_series.layout"
            :responsive="true"
            :display-mode-bar="false"
            :showTips="false"
          ></Plotly>
        </div>
      </section>
      <section
        id="display"
        class="mb-5 mt-9 plotly-container"
        v-if="selected === 'Yaw travel'"
      >
        <div v-if="selected === 'Yaw travel'">
          <DataLoading v-if="yawTravelLoading" class="analytics-loading" />
          <Plotly
            v-if="yawTravelFigure && !yawTravelLoading"
            id="plotly_diagram"
            :data="yawTravelFigure.data"
            :layout="yawTravelFigure.layout"
            :responsive="true"
            :display-mode-bar="false"
            :showTips="false"
          ></Plotly>
        </div>
      </section>
      <section
        id="display"
        class="mb-5 mt-9 plotly-container"
        v-if="selected === 'Yaw misalignment'"
      >
        <div v-if="selected === 'Yaw misalignment'">
          <DataLoading
            v-if="yawMisalignmentLoading"
            class="analytics-loading"
          />
          <Plotly
            v-else-if="yawMisalignmentFigure && !yawMisalignmentLoading"
            id="plotly_diagram"
            :data="yawMisalignmentFigureData"
            :layout="yawMisalignmentFigure.layout"
            :responsive="true"
            :display-mode-bar="false"
            :showTips="false"
          ></Plotly>
          <div class="no-data-backdrop" v-else>
            <div class="no-data-label d-flex justify-center">
              <span>No data available</span>
            </div>
          </div>
        </div>
      </section>
      <section
        id="display"
        class="mb-5 mt-9 plotly-container"
        v-if="selected === 'Swarm Edge alarms'"
      >
        <div v-if="selected === 'Swarm Edge alarms'">
          <DataLoading
            v-if="swarmEdgeAlarmsLoading"
            class="analytics-loading"
          />
          <Plotly
            v-else-if="swarmEdgeAlarmsFigure && !swarmEdgeAlarmsLoading"
            id="plotly_diagram"
            :data="swarmEdgeAlarmsFigure.data"
            :layout="swarmEdgeAlarmsFigure.layout"
            :responsive="true"
            :display-mode-bar="false"
            :showTips="false"
          ></Plotly>
          <div class="no-data-backdrop" v-else>
            <div class="no-data-label d-flex justify-center">
              <span>No data available</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";
import { mapState, mapActions } from "vuex";
import Plotly from "@/components/Plotly";
import DataLoading from "@/components/DataLoading";
import axios from "@/helpers/axiosAPI";

const { CalendarPanel } = DatePicker;

export default {
  name: "SwarmAnalytics",
  components: { DatePicker, CalendarPanel, Plotly, DataLoading },
  data() {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return {
      chartType: [
        "Availability",
        "Nacelle direction",
        "Nacelle tracking",
        "SCADA Latency",
        "Swarm Edge alarms",
        "Yaw misalignment",
        "Yaw travel",
      ], // TODO: Edge state
      selected: "Availability",
      endDate: new Date(),
      startDate: oneWeekAgo,
      layout: {},
      dayjs,
      edges: null,
      server: null,
      availabilityLoading: true,
      wsAvailabilityLoading: null,
      wsAvailabilityFigure: null,
      nacTrackingErrorLoading: null,
      nacTrackingErrorFigure: null,
      nacTrackingDelayLoading: null,
      nacTrackingDelayFigure: null,
      yawTravelLoading: null,
      yawTravelFigure: null,
      yawMisalignmentLoading: null,
      yawMisalignmentFigure: null,
      scadaLatencyLoading: null,
      scadaLatencyFigure: null,
      swarmEdgeAlarmsLoading: null,
      swarmEdgeAlarmsFigure: null,
      showYawMisalignmentAdjustments: true,
      nacDirLoading: null,
      nacDirFigures: {},
      nacDirDownloadUrl: null,
    };
  },
  props: {},
  computed: {
    ...mapState({
      // TODO: switch to site module for turbines
      turbines: (state) => state.sites.turbines,
      swarmEdgeTs: (state) => state.swarm.swarmEdgeTs,
      swarmAvailabilityPlots: (state) => state.swarm.swarmAvailabilityPlots,
      loading: (state) => state.swarm.loading.getSwarmEdgeTs,
    }),
    yawMisalignmentFigureData() {
      if (this.showYawMisalignmentAdjustments) {
        return this.yawMisalignmentFigure.data;
      }
      return this.yawMisalignmentFigure.data.filter(
        // Only adjustment plots have a dash so that is the metric for determining
        (plot) => plot.line.dash !== "dash",
      );
    },
  },
  methods: {
    ...mapActions({
      getSwarmEdgeTs: "swarm/getSwarmEdgeTs",
      getSwarmAvailabilityPlots: "swarm/getSwarmAvailabilityPlots",
    }),
    async updatePlotly() {
      if (this.selected === "Edge state") {
        await this.getSwarmEdgeTs({
          siteId: this.$route.params.siteId,
          startDate: dayjs(this.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(this.endDate).format("YYYY-MM-DD"),
        });
      } else if (this.selected === "Availability") {
        this.getWSAvailability();
        this.availabilityLoading = true;
        await this.getSwarmAvailabilityPlots({
          siteId: this.$route.params.siteId,
          startDate: dayjs(this.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(this.endDate).format("YYYY-MM-DD"),
        });
        this.edges = this.swarmAvailabilityPlots.edges;
        this.server = this.swarmAvailabilityPlots.server;
        this.availabilityLoading = false;
      } else if (this.selected === "Nacelle tracking") {
        this.nacTrackingErrorLoading = true;
        const paramsError = {
          start_date: dayjs(this.startDate).format("YYYY-MM-DD"),
          end_date: dayjs(this.endDate).format("YYYY-MM-DD"),
          metric: "rms",
          plot: true,
        };
        const siteId = this.$route.params.siteId;
        const respError = await axios.get(
          `/sites/${siteId}/swarm/nacelle-tracking`,
          { params: paramsError },
        );
        this.nacTrackingErrorLoading = false;
        if (respError.status === 200) {
          this.nacTrackingErrorFigure = respError.data.figure;
        } else {
          console.error("Error getting figure");
        }
        this.nacTrackingDelayLoading = true;
        const paramsDelay = {
          start_date: dayjs(this.startDate).format("YYYY-MM-DD"),
          end_date: dayjs(this.endDate).format("YYYY-MM-DD"),
          plot: true,
        };
        const respDelay = await axios.get(
          `/sites/${siteId}/swarm/nacelle-delay`,
          {
            params: paramsDelay,
          },
        );
        this.nacTrackingDelayLoading = false;
        if (respDelay.status === 200) {
          this.nacTrackingDelayFigure = respDelay.data.figure;
        } else {
          console.error("Error getting figure");
        }
      } else if (this.selected === "Nacelle direction") {
        this.nacDirLoading = true;
        const params = {
          start_ts: dayjs(this.startDate).format("YYYY-MM-DD"),
          end_ts: dayjs(this.endDate).format("YYYY-MM-DD"),
          plot: true,
        };
        const siteId = this.$route.params.siteId;
        const resp = await axios.get(
          `/sites/${siteId}/swarm/nacelle-direction`,
          { params },
        );
        this.nacDirLoading = false;
        if (resp.status === 200) {
          if (resp.data) {
            this.nacDirFigures = resp.data.figures;
            this.nacDirDownloadUrl = resp.data.download_url;
          } else {
            this.nacDirFigures = {};
            this.nacDirDownloadUrl = null;
          }
        } else {
          console.error("Error getting nacelle direction");
        }
      } else if (this.selected === "Yaw travel") {
        this.yawTravelLoading = true;
        const params = {
          start_date: dayjs(this.startDate).format("YYYY-MM-DD"),
          end_date: dayjs(this.endDate).format("YYYY-MM-DD"),
          plot: true,
        };
        const siteId = this.$route.params.siteId;
        const resp = await axios.get(`/sites/${siteId}/swarm/yaw-travel`, {
          params,
        });
        this.yawTravelLoading = false;
        if (resp.status === 200) {
          this.yawTravelFigure = resp.data.figure;
        } else {
          console.error("Error getting figure");
        }
      } else if (this.selected === "Yaw misalignment") {
        this.yawMisalignmentLoading = true;
        const params = {
          start_date: dayjs(this.startDate).format("YYYY-MM-DD"),
          end_date: dayjs(this.endDate).format("YYYY-MM-DD"),
          from_swarm_edge: true,
          plot: true,
        };
        const siteId = this.$route.params.siteId;
        const resp = await axios.get(`/sites/${siteId}/yaw-misalignment`, {
          params,
        });
        this.yawMisalignmentLoading = false;
        if (resp.status === 200) {
          this.yawMisalignmentFigure = resp.data.figure;
        } else {
          console.error("Error getting figure");
        }
      } else if (this.selected === "Swarm Edge alarms") {
        this.swarmEdgeAlarmsLoading = true;
        const params = {
          start_ts: dayjs(this.startDate).format("YYYY-MM-DD"),
          end_ts: dayjs(this.endDate).format("YYYY-MM-DD"),
          plot: true,
        };
        const siteId = this.$route.params.siteId;
        const resp = await axios.get(`/sites/${siteId}/swarm/edge-alarms`, {
          params,
        });
        this.swarmEdgeAlarmsLoading = false;
        if (resp.status === 200) {
          if (resp.data !== null) {
            this.swarmEdgeAlarmsFigure = resp.data.figure;
          } else {
            this.swarmEdgeAlarmsFigure = null;
          }
        } else {
          console.error("Error getting figure");
        }
      } else if (this.selected === "SCADA Latency") {
        this.scadaLatencyLoading = true;
        const params = {
          start_date: dayjs(this.startDate).format("YYYY-MM-DD"),
          end_date: dayjs(this.endDate).format("YYYY-MM-DD"),
          plot: true,
        };
        const siteId = this.$route.params.siteId;
        const resp = await axios.get(`/sites/${siteId}/swarm/scada-latency`, {
          params,
        });
        this.scadaLatencyLoading = false;
        if (resp.status === 200) {
          if (resp.data !== null) {
            this.scadaLatencyFigure = resp.data.figure;
          } else {
            this.scadaLatencyFigure = null;
          }
        } else {
          console.error("Error getting figure");
        }
      }
      this.setPlotlyLayout();
    },
    async getWSAvailability() {
      this.wsAvailabilityLoading = true;
      const params = {
        start_date: dayjs(this.startDate).format("YYYY-MM-DD"),
        end_date: dayjs(this.endDate).format("YYYY-MM-DD"),
        plot: true,
      };
      const siteId = this.$route.params.siteId;
      const resp = await axios.get(
        `/sites/${siteId}/swarm/wake-steering-availability`,
        {
          params,
        },
      );
      this.wsAvailabilityLoading = false;
      if (resp.status === 200) {
        if (resp.data.figures !== null) {
          this.wsAvailabilityFigure = resp.data.figures.availability_ts;
        } else {
          this.wsAvailabilityFigure = null;
        }
      } else {
        console.error("Error getting figure");
      }
    },
    setPlotlyLayout() {
      if (this.selected === "Edge state") {
        if (Object.keys(this.swarmEdgeTs).length > 0) {
          // eslint-disable-next-line no-undef
          const layout = structuredClone(this.swarmEdgeTs.layout);
          layout.width = 1000;
          this.layout = layout;
        }
      } else if (this.selected === "Nacelle tracking") {
        if (this.nacTrackingErrorFigure) {
          // eslint-disable-next-line no-undef
          const layout = structuredClone(this.nacTrackingErrorFigure.layout);
          layout.margin = { l: 80, b: 25, r: 80, t: 25 };
          this.nacTrackingErrorFigure.layout = layout;
        }
        if (this.nacTrackingDelayFigure) {
          // eslint-disable-next-line no-undef
          const layout = structuredClone(this.nacTrackingDelayFigure.layout);
          layout.margin = { l: 80, b: 25, r: 80, t: 25 };
          this.nacTrackingDelayFigure.layout = layout;
        }
      } else if (this.selected === "Nacelle direction") {
        if (this.nacDirFigures.mean_diff_bar) {
          // eslint-disable-next-line no-undef
          const layout = structuredClone(
            this.nacDirFigures.mean_diff_bar.layout,
          );
          layout.margin = { l: 80, b: 25, r: 80, t: 25 };
          this.nacDirFigures.mean_diff_bar.layout = layout;
        }
        if (this.nacDirFigures.diff_time_series) {
          // eslint-disable-next-line no-undef
          const layout = structuredClone(
            this.nacDirFigures.diff_time_series.layout,
          );
          layout.margin = { l: 80, b: 25, r: 80, t: 25 };
          this.nacDirFigures.diff_time_series.layout = layout;
        }
      } else if (this.selected === "Yaw travel") {
        if (this.yawTravelFigure) {
          // eslint-disable-next-line no-undef
          const layout = structuredClone(this.yawTravelFigure.layout);
          layout.margin = { l: 80, b: 25, r: 80, t: 25 };
          this.yawTravelFigure.layout = layout;
        }
      } else if (this.selected === "Yaw misalignment") {
        if (this.yawMisalignmentFigure) {
          // eslint-disable-next-line no-undef
          const layout = structuredClone(this.yawMisalignmentFigure.layout);
          layout.margin = { l: 80, b: 25, r: 80, t: 25 };
          this.yawMisalignmentFigure.layout = layout;
        }
      } else if (this.selected === "Swarm Edge alarms") {
        if (this.swarmEdgeAlarmsFigure) {
          // eslint-disable-next-line no-undef
          const layout = structuredClone(this.swarmEdgeAlarmsFigure.layout);
          layout.margin = { l: 80, b: 25, r: 80, t: 25 };
          this.swarmEdgeAlarmsFigure.layout = layout;
        }
      } else if (this.selected === "SCADA Latency") {
        if (this.scadaLatencyFigure) {
          // eslint-disable-next-line no-undef
          const layout = structuredClone(this.scadaLatencyFigure.layout);
          layout.margin = { l: 80, b: 25, r: 80, t: 25 };
          this.scadaLatencyFigure.layout = layout;
        }
      }
    },
    downloadData() {
      if (this.nacDirDownloadUrl) {
        let docUrl = document.createElement("a");
        docUrl.href = this.nacDirDownloadUrl;
        docUrl.id = "file-download";
        docUrl.click();
      }
    },
  },
  beforeMount() {
    this.updatePlotly();
    this.setPlotlyLayout();
  },
  watch: {
    selected: function (newVal) {
      if (this.selected === "Yaw misalignment") {
        // 90 days ago by default
        this.startDate = new Date(this.endDate - 1000 * 60 * 60 * 24 * 90);
      } else {
        // 7 days ago by default
        this.startDate = new Date(this.endDate - 1000 * 60 * 60 * 24 * 7);
      }
      this.updatePlotly();
    },
  },
};
</script>

<style lang="scss">
.swarm-analytics {
  .v-select {
    max-width: 200px;
  }
  .mx-datepicker {
    display: flex !important;
    align-items: flex-end !important;
  }
  .plotly-container {
    flex: 1;
  }

  .analytics-display {
    position: relative;
    background-color: var(--var-white-base);
    // min-height: 400px;
    .analytics-loading {
      padding-bottom: 3rem;
    }
  }

  .analytics-col {
    min-width: 240px;
    padding-bottom: 0;
  }
  .no-data-backdrop {
    // position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background-color: var(--v-backdrop-base);
  }

  .no-data-label {
    color: var(--v-white2-base);
    font-size: 1.5rem;
  }
  #display {
    width: 100%;
  }
}
</style>
