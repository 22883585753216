<template>
  <v-card
    min-height="80px"
    ref="summaryCard"
    outlined
    class="summary-widget h100 w100"
  >
    <div class="d-flex justify-center align-center" v-if="loading">
      <v-progress-circular
        :size="40"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="d-flex flex-column justify-space-between h100">
      <div class="d-flex align-center">
        <p class="widget-title widget-header">{{ headerContent }}</p>
        <PopUp
          v-if="popUpData && !Object.values(popUpData).every((x) => x === null)"
          :content="[headerContent]"
          class="losses-gains-popup"
        >
          <template v-slot:hover>
            <v-icon style="transform: scale(0.8)">{{ informationIcon }}</v-icon>
          </template>
          <template v-slot:content>
            <div v-if="popUpData.modelType">
              {{ popUpData.type }} model type: {{ popUpData.modelType }}
            </div>
            <div v-if="popUpData.aep_data_start_ts">
              AEP data start: {{ popUpData.aep_data_start_ts }} (UTC)
            </div>
            <div v-if="popUpData.aep_data_end_ts">
              AEP data end: {{ popUpData.aep_data_end_ts }} (UTC)
            </div>
            <div v-if="popUpData.data_start_ts">
              {{ popUpData.type }} data start:
              {{ popUpData.data_start_ts }} (UTC)
            </div>
            <div v-if="popUpData.data_end_ts">
              {{ popUpData.type }} data end: {{ popUpData.data_end_ts }} (UTC)
            </div>
            <div v-if="popUpData.loss_period_total_energy_mwh">
              Total energy in loss period:
              {{ popUpData.loss_period_total_energy_mwh }}
            </div>
            <div v-if="popUpData.gain_period_total_energy_mwh">
              Total energy in gain period:
              {{ popUpData.gain_period_total_energy_mwh }}
            </div>
            <div v-if="popUpData.aep_mwhpyr">
              AEP: {{ popUpData.aep_mwhpyr }}
            </div>
            <div v-if="popUpData.footNote" class="pt-2" style="font-size: 12px">
              {{ popUpData.footNote }}
            </div>
          </template>
        </PopUp>
      </div>
      <!-- Swarm availability -->
      <div
        id="availabilityBody"
        class="availability-body d-flex flex-wrap"
        v-if="typeof bodyContent === 'object' && bodyContent !== null"
      >
        <h3 ref="first" class="mt-0 mb-0 widget-body">
          Edges:
          {{
            swarmAvailability.edges_mean !== null
              ? roundToString(swarmAvailability.edges_mean, 1)
              : "TBD"
          }}%
        </h3>
        <span
          v-if="!removePipe"
          class="pipe ml-4 mr-4"
          :class="{ 'show-pipe': showPipe }"
          >|</span
        >
        <h3 ref="second" class="mt-0 mb-0 widget-body">
          Server:
          {{
            swarmAvailability.server !== null
              ? roundToString(swarmAvailability.server, 1)
              : "TBD"
          }}%
        </h3>
      </div>
      <!-- Issue detail page cards -->
      <div class="d-flex align-center" v-else>
        <h3 class="mr-2 text-h7 widget-body">
          {{ bodyContent }}
        </h3>
        <span
          v-if="bodyPercent || bodyPercent === 0"
          :class="[bodyPercent > 0 ? 'increase' : 'decrease', 'price-percent']"
        >
          {{ bodyPercent }}%
        </span>
      </div>
      <p class="widget-title widget-footer">
        <span class="mr-2">{{ footerContent }}</span>
        <span
          v-if="footerPercent || footerPercent === 0"
          :class="[
            footerPercent > 0 ? 'increase' : 'decrease',
            'total-percent',
          ]"
        >
          {{ footerPercent }}%
        </span>
      </p>
    </div>
  </v-card>
</template>

<script>
import PopUp from "@/components/PopUp";
import { roundToString } from "@/helpers/functions";
import { informationIcon } from "@/helpers/variables";

export default {
  name: "SummaryCard",
  components: {
    PopUp,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    headerContent: {
      type: String,
      default: "",
      required: true,
    },
    bodyContent: {
      type: [String, Object],
      default: null,
      required: true,
    },
    bodyPercent: {
      type: [String, Number],
    },
    footerContent: {
      type: String,
      default: "",
      required: true,
    },
    footerPercent: {
      type: Number,
    },
    popUpData: {
      type: Object,
      default: () => {},
      required: false,
    },
    swarmAvailability: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      roundToString,
      showPipe: true,
      removePipe: false,
      informationIcon,
    };
  },
  methods: {
    checkIfWrapped(entries) {
      const containerHeight = entries[0].contentRect.height;
      const firstChildHeight = entries[0].target.children[0].clientHeight;
      this.showPipe = !(containerHeight > firstChildHeight);
    },
    onResize() {
      this.removePipe = window.innerWidth < 900;
    },
  },
  mounted() {
    if (typeof this.bodyContent === "object" && this.bodyContent !== null) {
      const availabilityBody = this.$el.querySelector("#availabilityBody");
      const resizeObserver = new ResizeObserver(this.checkIfWrapped);
      resizeObserver.observe(availabilityBody);
    }
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style lang="scss">
.losses-gains-popup {
  .popup {
    max-width: 350px;
    right: -150px;
    padding: 0.75rem 1rem;
  }
}
</style>
<style lang="scss" scoped>
.pipe {
  visibility: hidden;
}
.show-pipe {
  visibility: visible;
}

.availability-body {
  margin: 10px 0;
  align-items: center;
}
</style>
