<template>
  <div>
    <div
      @click="cancel()"
      class="backdrop"
      v-if="serverDialog || edgeDialog"
    ></div>
    <div class="mb-4">
      <div class="error-alert__server" v-if="errorMessageServer">
        <v-alert type="error" dismissible elevation="2">
          {{ errorMessageServer }}
        </v-alert>
      </div>
      <DataTable
        className="no-background deployments-table"
        :items="serverDeploymentsTableData"
        table="server_deployments"
        :loading="loading.postServerDeployment || loading.getServerDeployments"
        itemKey="id"
        :isSwarm="true"
        :headers="mutableServerHeaders"
        sortBy="start_ts"
        :sortDesc="true"
      >
        <template v-slot:top>
          <v-toolbar flat dense class="no-background toolbar mb-2">
            <v-toolbar-title>Server deployments</v-toolbar-title>
            <v-toolbar-items v-if="isInternalAdmin">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <!-- Adding a row to server table -->
                  <v-icon
                    v-on="on"
                    v-bind="attrs"
                    class="pointer ml-1 add-server-row__icon"
                    size="1.7rem"
                    @click="openAddRow('server')"
                    >mdi-plus</v-icon
                  >
                </template>
                <span>Create new deployment</span>
              </v-tooltip>
              <EditDeploymentsTablesWidget
                :serverDialog="serverDialog"
                :tableType="table"
                :turbines="turbines"
                :createDeploymentLoading="createDeploymentLoading"
                @cancel="cancel"
                @addNewRow="addNewRow"
              />
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-toolbar-items class="mr-3">
              <div class="d-flex align-center">
                <label class="mr-2 mt-3">Only show latest</label>
                <v-switch
                  dense
                  hide-details
                  v-model="showLatestServer"
                  class="switch"
                ></v-switch>
              </div>
            </v-toolbar-items>
            <div class="column-filter-wrapper">
              <v-tooltip top :open-delay="popupDelay">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="server-cog-icon"
                    class="pointer"
                    v-on="on"
                    v-bind="attrs"
                    >mdi-cog-outline</v-icon
                  >
                </template>
                <span>Column filters</span>
              </v-tooltip>
              <CustomMenuFilter
                :menuOpen="serverColFilterMenuOpen"
                menuActivator="#server-cog-icon"
                :menuItems="serverColFilterMenuItems"
                :showAll="showAllServerCols"
                offset="y"
                filterType="column"
                table="server_deployments_table"
                @changeFilters="setServerTableColsToShow"
                @clearSelectedItems="clearServerSelectedColItems"
                @setSearchText="setServerColFilterSearchText"
                @clearFilterSearch="clearServerColFilterSearch"
              />
            </div>
          </v-toolbar>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id" class="server-row">
              <td v-if="checkColumn('start_ts', 'server')">
                <span></span>{{ formatTimestamp(item.start_ts) }}
              </td>
              <td v-if="checkColumn('end_ts', 'server')">
                {{ formatTimestamp(item.end_ts) }}
              </td>
              <td v-if="checkColumn('user_email', 'server')">
                {{ item.user_email }}
              </td>
              <td v-if="checkColumn('expect_perf_change', 'server')">
                {{ item.expect_perf_change ? "Yes" : "No" }}
              </td>
              <td v-if="checkColumn('version', 'server')">
                {{ item.version }}
              </td>
              <td v-if="checkColumn('values', 'server')">
                <v-menu
                  open-on-hover
                  :open-delay="popupDelay"
                  bottom
                  offset-y
                  :disabled="addingServerRow"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="monospace">
                      {{ shortValues(item.values) }}
                    </div>
                  </template>
                  <vue-code-highlight language="javascript"
                    ><div>
                      <pre>{{ item.values }}</pre>
                    </div></vue-code-highlight
                  >
                </v-menu>
              </td>
              <td v-if="checkColumn('notes', 'server')">
                <v-menu
                  open-on-hover
                  :open-delay="popupDelay"
                  max-width="400px"
                  bottom
                  offset-y
                  :disabled="addingServerRow"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <vue-markdown
                        :breaks="false"
                        :anchor-attributes="anchorAttrs"
                      >
                        {{
                          item.notes ? shortNotes(item.notes) : ""
                        }}</vue-markdown
                      >
                    </div>
                  </template>
                  <v-list class="notes-list">
                    <v-list-item>
                      <v-list-item-content>
                        <vue-markdown
                          :breaks="false"
                          :anchor-attributes="anchorAttrs"
                        >
                          {{ item.notes }}</vue-markdown
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </DataTable>
    </div>
    <div class="mt-4 edges-table">
      <div class="error-alert__edges" v-if="errorMessageEdges">
        <v-alert type="error" dismissible elevation="2">
          {{ errorMessageEdges }}
        </v-alert>
      </div>
      <!-- Swarm Edge Deployments -->
      <DataTable
        :loading="loading.postEdgeDeployment || loading.getEdgeDeployments"
        :items="edgeDeploymentsTableData"
        table="edge_deployments"
        :isSwarm="true"
        itemKey="id"
        :headers="mutableEdgeHeaders"
      >
        <template v-slot:top>
          <v-toolbar flat dense class="no-background toolbar">
            <v-toolbar-title>Edge deployments</v-toolbar-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-toolbar-items>
                  <v-icon
                    v-on="on"
                    v-bind="attrs"
                    class="pointer ml-1 add-edges-row__icon"
                    size="1.7rem"
                    @click="openAddRow('edge')"
                    >mdi-plus</v-icon
                  >
                </v-toolbar-items>
              </template>
              <span>Create new deployment</span>
            </v-tooltip>
            <EditDeploymentsTablesWidget
              :edgeDialog="edgeDialog"
              :tableType="table"
              :turbines="turbines"
              :createDeploymentLoading="createDeploymentLoading"
              @cancel="cancel"
              @addNewRow="addNewRow"
            />
            <v-spacer></v-spacer>
            <v-toolbar-items class="mr-3">
              <div class="d-flex align-center">
                <label class="mr-2 mt-3">Only show latest</label>
                <v-switch
                  dense
                  hide-details
                  v-model="showLatestEdges"
                  class="switch"
                  :disabled="latestEdgeDeployments.length === 0"
                ></v-switch>
              </div>
            </v-toolbar-items>
            <div class="column-filter-wrapper">
              <v-tooltip top :open-delay="popupDelay">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="edge-cog-icon"
                    class="pointer"
                    v-on="on"
                    v-bind="attrs"
                    >mdi-cog-outline</v-icon
                  >
                </template>
                <span>Column filters</span>
              </v-tooltip>
              <CustomMenuFilter
                :menuOpen="edgeColFilterMenuOpen"
                menuActivator="#edge-cog-icon"
                :menuItems="edgeColFilterMenuItems"
                :showAll="showAllEdgeCols"
                offset="y"
                filterType="column"
                table="edge_deployments_table"
                @changeFilters="setEdgeTableColsToShow"
                @clearSelectedItems="clearEdgeSelectedColItems"
                @setSearchText="setEdgeColFilterSearchText"
                @clearFilterSearch="clearEdgeColFilterSearch"
              />
            </div>
          </v-toolbar>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td v-if="checkColumn('turbine_name', 'edge')">
                {{ item.turbine_name }}
              </td>
              <td v-if="checkColumn('end_ts', 'edge')">
                {{ formatTimestamp(item.end_ts) }}
              </td>
              <td v-if="checkColumn('user_email', 'edge')">
                {{ item.user_email }}
              </td>
              <td v-if="checkColumn('version', 'edge')">
                {{ item.version }}
              </td>
              <td v-if="checkColumn('config', 'edge')">
                <v-menu
                  open-on-hover
                  :open-delay="popupDelay"
                  bottom
                  offset-y
                  :disabled="addingEdgeRow"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="monospace">
                      {{ shortValues(item.config) }}
                    </div>
                  </template>
                  <vue-code-highlight language="javascript"
                    ><div>
                      <pre>{{ item.config }}</pre>
                    </div></vue-code-highlight
                  >
                </v-menu>
              </td>
              <td v-if="checkColumn('notes', 'edge')">
                <v-menu
                  open-on-hover
                  :open-delay="popupDelay"
                  max-width="400px"
                  bottom
                  offset-y
                  :disabled="addingEdgeRow"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <vue-markdown
                        :breaks="false"
                        :anchor-attributes="anchorAttrs"
                      >
                        {{
                          item.notes ? shortNotes(item.notes) : ""
                        }}</vue-markdown
                      >
                    </div>
                  </template>
                  <v-list class="notes-list">
                    <v-list-item>
                      <v-list-item-content>
                        <vue-markdown
                          :breaks="false"
                          :anchor-attributes="anchorAttrs"
                        >
                          {{ item.notes }}</vue-markdown
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DataTable from "@/components/DataTable";
import VueMarkdown from "vue-markdown-v2";
import EditDeploymentsTablesWidget from "@/components/EditDeploymentsTablesWidget";
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/prism-okaidia.css";
import { popupDelay } from "@/helpers/variables";
import { groupBy } from "@/helpers/functions";
import CustomMenuFilter from "@/components/CustomMenuFilter";

export default {
  name: "SwarmDeployments",
  components: {
    DataTable,
    EditDeploymentsTablesWidget,
    VueCodeHighlight,
    VueMarkdown,
    CustomMenuFilter,
  },
  props: {
    edges: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      menuOpenServer: false,
      menuOpenEdge: false,
      showLatestServer: true,
      latestServerDeployment: [],
      allServerDeployments: [],
      showLatestEdges: true,
      latestEdgeDeployments: [],
      allEdgeDeployments: [],
      elevateNotification: false,
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      addingServerRow: false,
      addingEdgeRow: false,
      tableType: "server",
      rowItemId: null,
      errorMessageServer: "",
      errorMessageEdges: "",
      x: 330,
      y: 130,
      serverDialog: false,
      edgeDialog: false,
      popupDelay,
      createDeploymentLoading: false,
      selectableServerHeaders: [],
      mutableServerHeaders: [],
      serverColFilterMenuOpen: false,
      edgeColFilterMenuOpen: false,
      searchServerColMenuText: "",
      showAllServerCols: false,
      selectableEdgeHeaders: [],
      mutableEdgeHeaders: [],
      searchEdgeColMenuText: "",
      showAllEdgeCols: false,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.userData,
      serverDeployments: (state) => state.swarm.serverDeployments,
      edgeDeployments: (state) => state.swarm.edgeDeployments,
      loading: (state) => state.swarm.loading,
      postServerResponse: (state) => state.swarm.postServerResponse,
      postEdgeResponse: (state) => state.swarm.postEdgeResponse,
    }),
    serverHeaders() {
      const headers = [
        {
          id: 1,
          text: "Started",
          value: "start_ts",
          class: "nowrap-col",
          show: true,
        },
        {
          id: 2,
          text: "Completed",
          value: "end_ts",
          class: "nowrap-col",
          show: true,
        },
        {
          id: 3,
          text: "Initiated by",
          value: "user_email",
          width: "100px",
          show: true,
        },
        {
          id: 4,
          text: "Expect perf. change",
          value: "expect_perf_change",
          show: true,
        },
        { id: 5, text: "Helm chart version", value: "version", show: true },
        { id: 6, text: "Helm chart values", value: "values", show: true },
        { id: 7, text: "Notes", value: "notes", show: true },
      ];
      return headers;
    },
    edgeHeaders() {
      const headers = [
        { id: 1, text: "Turbine", value: "turbine_name", show: true },
        { id: 2, text: "Timestamp (UTC)", value: "end_ts", show: true },
        { id: 3, text: "User email", value: "user_email", show: true },
        { id: 4, text: "Version", value: "version", show: true },
        { id: 5, text: "Config", value: "config", show: true },
        { id: 6, text: "Notes", value: "notes", show: true },
      ];
      return headers;
    },
    // Results of filter search if search text present
    serverColFilterMenuItems() {
      if (this.searchServerColMenuText) {
        return this.selectableServerHeaders?.filter((header) =>
          header.text
            .toLowerCase()
            .includes(this.searchServerColMenuText?.toLowerCase()),
        );
      } else {
        return this.selectableServerHeaders;
      }
    },
    edgeColFilterMenuItems() {
      if (this.searchEdgeColMenuText) {
        return this.selectableEdgeHeaders?.filter((header) =>
          header.text
            .toLowerCase()
            .includes(this.searchEdgeColMenuText?.toLowerCase()),
        );
      } else {
        return this.selectableEdgeHeaders;
      }
    },
    hasWriteAccess() {
      if (this.userData && this.userData.permissions.length > 0) {
        return (
          this.userData.permissions[0].level.includes("admin") ||
          this.userData.permissions[0].level.includes("write")
        );
      } else {
        return false;
      }
    },
    table() {
      return this.tableType;
    },
    isInternalAdmin() {
      if (this.userData && this.userData.permissions.length > 0) {
        return this.userData.is_internal && this.userData.is_admin;
      } else {
        return false;
      }
    },
    serverDeploymentsTableData() {
      if (this.showLatestServer) {
        return this.latestServerDeployment;
      } else {
        return this.allServerDeployments;
      }
    },
    edgeDeploymentsTableData() {
      if (this.showLatestEdges) {
        return this.latestEdgeDeployments;
      } else {
        return this.allEdgeDeployments;
      }
    },
    turbines() {
      if (this.edges.length > 0) {
        let turbineArr = [];
        this.edges.forEach((turbine) => {
          turbineArr.push({
            turbine_id: turbine.turbine_id,
            turbine_name: turbine.turbine_name,
          });
        });

        return turbineArr;
      } else {
        return [];
      }
    },
  },
  watch: {
    menuOpenServer: {
      handler(data) {
        if (!data) {
          this.addingServerRow = false;
        }
      },
    },
    menuOpenEdge: {
      handler(data) {
        if (!data) {
          this.addingEdgeRow = false;
        }
      },
    },
    serverDeployments: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.setInitialTableData("server");
        }
      },
    },
    turbines: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.setInitialTableData("edge");
        }
      },
    },
    serverHeaders: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.selectableServerHeaders = [...data];
          this.mutableServerHeaders = [...data];
          this.showAllServerCols = true;
        }
      },
    },
    edgeHeaders: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.selectableEdgeHeaders = [...data];
          this.mutableEdgeHeaders = [...data];
          this.showAllEdgeCols = true;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      postServerDeployment: "swarm/postServerDeployment",
      postEdgeDeployment: "swarm/postEdgeDeployment",
    }),
    setInitialTableData(type) {
      if (type === "server" && this.serverDeployments.length > 0) {
        this.latestServerDeployment = [];
        const latest = this.serverDeployments.reduce((a, b) =>
          a.start_ts > b.start_ts ? a : b,
        );
        this.latestServerDeployment.push(latest);

        this.allServerDeployments = this.serverDeployments.map((item) => ({
          start_ts: item.start_ts,
          end_ts: item.end_ts,
          user_email: item.user_email,
          expect_perf_change: item.expect_perf_change,
          version: item.version,
          values: item.values,
          notes: item.notes,
        }));
      }
      if (type === "edge" && this.edgeDeployments.length > 0) {
        let latestEdgeDeployments = [];
        const grouped = groupBy(this.edgeDeployments, "turbine_id");
        for (const key in grouped) {
          let latest = {};
          latest = grouped[key].reduce((a, b) =>
            a.start_ts > b.start_ts ? a : b,
          );
          latestEdgeDeployments.push(latest);
        }
        this.latestEdgeDeployments = latestEdgeDeployments.map((item) => ({
          turbine_id: item.turbine_id,
          turbine_name: this.findTurbineName(item.turbine_id),
          end_ts: item.end_ts,
          user_email: item.user_email,
          version: item.version,
          config: item.config,
          notes: item.notes,
        }));
        this.allEdgeDeployments = this.edgeDeployments.map((item) => ({
          turbine_id: item.turbine_id,
          turbine_name: this.findTurbineName(item.turbine_id),
          end_ts: item.end_ts,
          user_email: item.user_email,
          version: item.version,
          config: item.config,
          notes: item.notes,
        }));
      }
    },
    checkColumn(column, table) {
      if (table === "server") {
        return this.selectableServerHeaders.find(
          (c) => c.value === column && c.show,
        );
      } else if (table === "edge") {
        return this.selectableEdgeHeaders.find(
          (c) => c.value === column && c.show,
        );
      }
    },
    setServerTableColsToShow(item) {
      this.toggleColSelection(item, "server");
    },
    setEdgeTableColsToShow(item) {
      this.toggleColSelection(item, "edge");
    },
    toggleColSelection(item, table) {
      let index = -1;
      let tableHeaders = [];
      let selectableHeaders = [];
      let mutableHeaders = [];
      let isAMatch = false;
      if (table === "server") {
        tableHeaders = this.serverHeaders;
        index = this.selectableServerHeaders.indexOf(item);
        selectableHeaders = this.selectableServerHeaders;
        mutableHeaders = this.mutableServerHeaders;
      } else {
        tableHeaders = this.edgeHeaders;
        index = this.selectableEdgeHeaders.indexOf(item);
        selectableHeaders = this.selectableEdgeHeaders;
        mutableHeaders = this.mutableEdgeHeaders;
      }
      // Determine which toggle function to use
      if (item === "selectAll") {
        this.selectAllColumns(table);
      } else if (item === "reset") {
        if (table === "server") {
          this.clearServerColFilterSearch();
          this.selectableServerHeaders.forEach(
            (header) => (header.show = true),
          );
          this.mutableServerHeaders = [...this.selectableServerHeaders];
          this.showAllServerCols = true;
        } else {
          this.clearEdgeColFilterSearch();
          this.selectableEdgeHeaders.forEach((header) => (header.show = true));
          this.mutableEdgeHeaders = [...this.selectableEdgeHeaders];
          this.showAllEdgeCols = true;
        }
      } else {
        // Toggle individual columns
        if (index > -1) {
          selectableHeaders[index].show = !selectableHeaders[index].show;
          if (tableHeaders.length > 0) {
            for (const header of tableHeaders) {
              if (
                header.id === selectableHeaders[index].id &&
                !selectableHeaders[index].show
              ) {
                mutableHeaders.splice(mutableHeaders.indexOf(header), 1);
                isAMatch = true;
                break;
              }
            }
            // Place or remove the correct column from mutable header array
            if (!isAMatch) {
              let closestIndex = -1; // Initialize index of closest ID
              let minDifference = Infinity; // Initialize minimum difference
              const idOfMissingHeader = selectableHeaders[index].id;
              if (
                mutableHeaders.length > 0 &&
                mutableHeaders[0]?.id < idOfMissingHeader
              ) {
                for (let i = 0; i < mutableHeaders.length; i++) {
                  const difference = idOfMissingHeader - mutableHeaders[i].id;
                  if (difference > 0 && difference < minDifference) {
                    closestIndex = i;
                    minDifference = difference;
                  }
                }
                // Insert header just after the header with the closest smaller id
                mutableHeaders.splice(
                  closestIndex + 1,
                  0,
                  selectableHeaders[index],
                );
              } else {
                mutableHeaders.unshift(selectableHeaders[index]);
              }
            }
          }
          // Set active table headers
          if (table === "server") {
            this.selectableServerHeaders = selectableHeaders;
            this.mutableServerHeaders = mutableHeaders;
            this.showAllServerCols =
              this.mutableServerHeaders === this.serverHeaders;
          } else {
            this.selectableEdgeHeaders = selectableHeaders;
            this.mutableEdgeHeaders = mutableHeaders;
            this.showAllEdgeCols = this.mutableEdgeHeaders === this.edgeHeaders;
          }
        }
      }
    },
    selectAllColumns(table) {
      let tempHeaders = [];
      let tempMutableHeaders = [];
      let showAll = false;
      if (table === "server") {
        tempHeaders = this.selectableServerHeaders;
        tempMutableHeaders = this.mutableServerHeaders;
        this.clearServerColFilterSearch();
      } else {
        tempHeaders = this.selectableEdgeHeaders;
        tempMutableHeaders = this.mutableEdgeHeaders;
        this.clearEdgeColFilterSearch();
      }

      for (const header of tempHeaders) {
        if (!header.show) {
          showAll = false;
          break;
        }
        showAll = true;
      }
      if (showAll) {
        tempHeaders.forEach((header) => (header.show = false));
        tempMutableHeaders = [];
      } else {
        tempHeaders.forEach((header) => (header.show = true));
        tempMutableHeaders = [...tempHeaders];
      }

      if (table === "server") {
        this.selectableServerHeaders = tempHeaders;
        this.mutableServerHeaders = tempMutableHeaders;
        this.showAllServerCols =
          this.mutableServerHeaders.length === this.serverHeaders.length;
      } else {
        this.selectableEdgeHeaders = tempHeaders;
        this.mutableEdgeHeaders = tempMutableHeaders;
        this.showAllEdgeCols =
          this.mutableEdgeHeaders.length === this.edgeHeaders.length;
      }
    },
    clearServerSelectedColItems() {
      this.clearServerColFilterSearch();
      this.selectableServerHeaders.forEach((header) => (header.show = false));
      this.mutableServerHeaders = [];
      this.showAllServerCols = false;
    },
    clearEdgeSelectedColItems() {
      this.clearEdgeColFilterSearch();
      this.selectableEdgeHeaders.forEach((header) => (header.show = false));
      this.mutableEdgeHeaders = [];
      this.showAllEdgeCols = false;
    },
    clearServerColFilterSearch() {
      this.searchServerColMenuText = "";
    },
    clearEdgeColFilterSearch() {
      this.searchEdgeColMenuText = "";
    },
    setServerColFilterSearchText(searchText) {
      this.searchServerColMenuText = searchText;
    },
    setEdgeColFilterSearchText(searchText) {
      this.searchEdgeColMenuText = searchText;
    },
    openAddRow(type) {
      this.tableType = type;
      if (type === "server") {
        this.addingServerRow = true;
        this.addingEdgeRow = false;
        this.serverDialog = true;
        this.edgeDialog = false;
      } else {
        this.addingEdgeRow = true;
        this.addingServerRow = false;
        this.edgeDialog = true;
        this.serverDialog = false;
      }
    },
    toggleEditDialog(addingRow, type) {
      if (type === "server") {
        this.addingServerRow = !addingRow;
      } else if (type === "edge") {
        this.addingEdgeRow = !addingRow;
      }
    },
    async addNewRow(newRow, table) {
      this.createDeploymentLoading = true;
      this.addingServerRow = false;
      this.addingEdgeRow = false;
      if (table === "server") {
        await this.postServerDeployment(newRow);
      } else if (table === "edge") {
        await this.postEdgeDeployment(newRow);
      }
      if (this.postServerResponse.success) {
        this.$emit("getServerDeployments");
      } else if (this.postEdgeResponse.success) {
        this.$emit("getEdgeDeployments");
      }
      this.serverDialog = false;
      this.edgeDialog = false;
      this.createDeploymentLoading = false;
    },
    cancel() {
      this.addingServerRow = false;
      this.addingEdgeRow = false;
      this.serverDialog = false;
      this.edgeDialog = false;
    },
    formatTimestamp(timestamp) {
      if (timestamp) {
        const splitTS = timestamp.split("T");
        const datetime = splitTS[0] + " " + splitTS[1];

        return datetime;
      } else {
        return null;
      }
    },
    shortValues(values) {
      if (JSON.stringify(values).length > 10) {
        return JSON.stringify(values, null, 1).substring(0, 21) + "...";
      } else {
        return "";
      }
    },
    shortNotes(notes) {
      if (notes.length > 21) {
        return notes.substring(0, 20) + "...";
      } else {
        return notes;
      }
    },
    findTurbineName(id) {
      if (this.turbines.length > 0) {
        const found = this.turbines.find(
          (turbine) => turbine.turbine_id === id,
        );
        if (found) {
          return found.turbine_name;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  margin-top: 5px;
}

::v-deep {
  .toolbar .v-icon:hover {
    color: #13458d;
  }
  .no-background .v-toolbar__content {
    padding-right: 0;
    padding-left: 0;
  }
}

::v-deep {
  .edit-toolbar {
    .delete.v-icon:hover {
      color: #b30000;
    }
  }
}

.elevated {
  box-shadow: 0 2px 3px 0.5px rgba(0, 0, 0, 0.5);
  background-color: rgba(242, 242, 242, 1) !important;
}

.server-table {
  position: relative;
}

.error-alert__server {
  position: absolute;
  top: 45%;
  left: 5%;
  right: 5%;
  z-index: 1400;
}

.edges-table {
  position: relative;
}

.error-alert__edges {
  position: absolute;
  top: 30%;
  left: 5%;
  right: 5%;
  z-index: 1200;
}

.server-table__backdrop,
.edges-table__backdrop {
  position: absolute;
  top: 1%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1100;
}

.add-server-row__dialog {
  position: absolute;
  top: -25%;
  left: 10%;
  width: 80%;
  max-height: 650px;
  overflow-y: auto;
  z-index: 1150;
}

.v-dialog__content {
  z-index: 10000000 !important;
}

.add-server-row__icon,
.add-edges-row__icon {
  margin-top: 2px;
}

::v-deep {
  .language-javascript {
    margin: 0;
    pre {
      background-color: transparent;
    }
    code {
      background-color: transparent;
    }
  }
}
::v-deep {
  .notes-list .v-list-item:hover {
    background-color: white;
  }
  .notes-list .v-list-item__content div,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Inter, sans-serif;
  }
}

.add-edges-row__dialog {
  position: absolute;
  top: 15%;
  left: 10%;
  height: 100%;
  width: 80%;
  z-index: 1150;
}

.delete-server-conf__anchor {
  position: relative;
}

.truncate {
  min-width: 1px;
  max-width: 1px;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-server-conf {
  position: absolute;
  top: 90%;
  left: 20%;
  z-index: 100;
}
::v-deep {
  .monospace {
    font-family: monospace !important;
    font-size: 1rem;
  }
  .token {
    font-family: monospace !important;
    font-size: 1rem;
  }
}
::v-deep {
  .deployments-table th.nowrap-col {
    white-space: nowrap;
  }
}
</style>
<style lang="scss">
.deployments-table th {
  white-space: normal;
}
</style>
