<template>
  <div>
    <v-row class="widget-row">
      <v-col class="site-summary-cards-col">
        <CapacityCard
          :data="summary"
          :loading="loading"
          :turbineModels="turbineModels"
        />
      </v-col>
      <v-col class="site-summary-cards-col">
        <PowerPriceCard
          :data="summary"
          :loading="loading"
          :powerPriceLoading="powerPriceLoading"
          :hasWriteAccess="hasWriteAccess"
          @editPowerPrice="editPowerPrice"
        />
      </v-col>
      <v-col class="site-summary-cards-col">
        <IssueCountCard
          :data="summary"
          :loading="loading"
          :popupLoading="popupLoading"
          :openIssueTurbineCount="openIssueTurbineCount"
          :turbineCount="turbineCount"
          :isSiteDash="true"
          @goToIssuesTable="goToIssuesTable"
          @goToChecksTab="goToChecksTab"
        />
      </v-col>
      <v-col class="site-summary-cards-col">
        <AepPotentialCard :data="summary" :loading="loading" />
      </v-col>
      <v-col class="site-summary-cards-col">
        <GainsFromFixedCard :data="summary" :loading="loading" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CapacityCard from "@/components/SummaryCards/CapacityCard";
import PowerPriceCard from "@/components/SummaryCards/PowerPriceCard";
import AepPotentialCard from "./SummaryCards/AepPotentialCard.vue";
import GainsFromFixedCard from "./SummaryCards/GainsFromFixedCard.vue";
import IssueCountCard from "./SummaryCards/IssueCountCard.vue";

export default {
  name: "SiteInfo",
  components: {
    CapacityCard,
    PowerPriceCard,
    AepPotentialCard,
    GainsFromFixedCard,
    IssueCountCard,
  },
  data() {
    return {
      isEditPP: false,
      date: null,
      price: this.summary?.powerPrice?.usd,
      formValid: false,
      currentDay: null,
      textFieldClass: "d-flex align-center justify-center currency-input",
      dateValid: true,
      priceValid: true,
    };
  },
  props: {
    summary: {
      type: Object,
      required: true,
      default: () => {},
    },
    openIssueTurbineCount: {
      type: Number,
      required: false,
      default: null,
    },
    turbineCount: {
      type: Number,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    popupLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasWriteAccess: {
      type: Boolean,
      required: false,
      default: false,
    },
    turbineModels: {
      type: Array,
      required: false,
      default: () => [],
    },
    powerPriceLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    goToIssuesTable(type) {
      this.$emit("goToIssuesTable", type, "summary");
    },
    goToChecksTab() {
      this.$emit("goToChecksTab");
    },
    editPowerPrice(powerPriceObj) {
      this.$emit("editPowerPrice", powerPriceObj);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables";
.summary-widget {
  .models-text {
    font-size: 1rem;
  }
}
::v-deep .check:hover {
  text-decoration: underline;
  cursor: pointer;
}

.widget-row {
  position: relative;
}

.date-error__alert {
  position: absolute;
  top: 100%;
  z-index: 10;
}

::v-deep {
  .close-icon.v-icon:hover,
  .edit-icon.v-icon:hover {
    color: $primaryBlue;
  }
}

::v-deep {
  .save-icon.v-icon:hover {
    color: $accentGreen;
  }
}

::v-deep {
  .currency-input,
  .focused-price-input,
  .price-error {
    max-width: 150px;
    .v-input__slot::before {
      border-style: none !important;
    }
    .v-input__slot::after {
      border: none !important;
    }
    .v-input__prepend-inner {
      margin: 0;
      padding: 0;
      text-align: center;
    }
    .v-icon {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

::v-deep {
  .currency-input {
    .v-input {
      border: 1px solid #ccc; // turbine aep
      border-radius: 4px;
      font-size: 14px;
    }
    .v-input:hover {
      border-color: $primaryBlue !important;
    }
  }
}

::v-deep {
  .focused-price-input {
    .v-input {
      border: 1px solid $primaryBlue;
      border-radius: 4px;
      font-size: 14px;
    }
  }
}

.edit-widget {
  padding: 6px 16px;
  font-family: "Inter";
  border-radius: 8px !important;
}

::v-deep {
  .price-error {
    .v-input {
      border: 1px solid red; //sites/{siteId}/power-price POST // turbine aep
      border-radius: 4px;
      font-size: 14px;
    }
  }
}

::v-deep {
  .date-picker {
    width: 140px !important;
    .mx-input {
      box-shadow: none !important;
      webkit-box-shadow: none !important;
    }
  }
}

::v-deep {
  .date-error {
    .mx-input {
      border-color: red;
    }
  }
}

// Custom column sizes for card widgets
.site-summary-cards-col {
  flex-basis: 100%;
  max-width: 100%;
}

@media (min-width: 740px) {
  .site-summary-cards-col {
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
  }
}

@media (min-width: 980px) {
  .site-summary-cards-col {
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
  }
}

@media (min-width: 1350px) {
  .site-summary-cards-col {
    flex-basis: calc(100% / 5);
    max-width: calc(100% / 5);
  }
  .site-summary-cards-col:nth-child(5) {
    flex-basis: calc(100% / 5);
    max-width: calc(100% / 5);
  }
}
</style>
