<template>
  <v-dialog v-model="openDialog" hide-overlay eager>
    <v-card>
      <v-card-title class="edit-deployment-title white--text"
        ><h2>{{ title }}</h2></v-card-title
      >
      <v-card-text class="edit-deployment-content pt-4">
        <v-form ref="deploymentForm" v-model="formValid">
          <div class="form-container">
            <div v-if="tableType === 'server'">
              <div id="version-perf" class="d-flex">
                <div class="mr-10 first-col">
                  <p class="mb-1 label-text">Helm chart version</p>
                  <v-text-field
                    placeholder="0.0.0"
                    v-model="rowData.version"
                    type="text"
                    outlined
                    dense
                    v-on:keydown="preventLetters"
                    class="version-input"
                    :rules="rules.versionServer"
                    :class="{ 'form-input': !formValid && !rowData.version }"
                    @focus="inputFocused"
                  ></v-text-field>
                </div>
                <div class="mr-1 first-col">
                  <div>
                    <p class="mb-1 label-text">Expect performance change?</p>
                    <v-select
                      v-model="selected"
                      :items="perfChangeItems"
                      outlined
                      dense
                      class="perf-change-input"
                      @change="editPerfChange"
                      @focus="inputFocused"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap justify-center">
                <div id="values">
                  <div class="values-text mr-1">
                    <div class="d-flex justify-space-between">
                      <p class="mb-1 label-text">Helm chart values</p>
                      <p class="preview-json" @click="formatJson('server')">
                        Format
                      </p>
                    </div>
                    <v-textarea
                      v-model="rowData.values"
                      outlined
                      rows="14"
                      no-resize
                      placeholder="{ }"
                      :rules="rules.valuesJson"
                      class="text-area-input"
                      :class="{
                        'form-input':
                          (!formValid && !rowData.values) || formatJsonError,
                      }"
                      @blur="valueInput($event, 'server')"
                      @focus="handleFocusValues"
                    ></v-textarea>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-center">
                <v-alert v-model="formatJsonError" dismissible type="error"
                  >{{ errorMessage }}
                  <template v-slot:close>
                    <v-icon class="ml-1" color="white" @click="closeError"
                      >mdi-close-circle-outline</v-icon
                    >
                  </template>
                </v-alert>
              </div>
              <div id="notes">
                <div class="notes-editor mb-8">
                  <div class="editor">
                    <p class="mb-1 label-text">Notes</p>
                    <v-md-editor
                      v-model="rowData.notes"
                      mode="edit"
                      height="250px"
                      left-toolbar="bold italic strikethrough ul ol link image"
                      @change="editNotes($event, 'server')"
                      @focus="inputFocused"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="d-flex flex-column flex-wrap justify-center">
                <div id="turbine" class="d-flex">
                  <div class="mr-5 turbine-select">
                    <p class="mb-1 label-text">Turbine</p>
                    <v-select
                      v-model="turbineSelected"
                      :items="turbineSelections"
                      outlined
                      dense
                      item-text="turbine_name"
                      item-value="turbine_id"
                      class="perf-change-input"
                      @focus="inputFocused"
                    >
                    </v-select>
                  </div>
                  <div class="date-wrapper mr-5">
                    <p class="mb-1 label-text">Timestamp</p>
                    <div class="date-picker-container">
                      <date-picker
                        type="datetime"
                        v-model="date"
                        :class="{ 'date-error': datetimeInvalid }"
                        @pick="datetimeInvalid = false"
                        @focus="inputFocused"
                      >
                        <template v-slot:content="slotProps">
                          <div class="d-flex">
                            <calendar-panel
                              :disabled-date="disabledDates"
                              :value="slotProps.value"
                              @select="slotProps.emit"
                            ></calendar-panel>
                            <time-panel
                              :disabled-time="
                                (date) => date > new Date().toLocaleString()
                              "
                              show-time-header
                              :value="slotProps.value"
                              @select="slotProps.emit"
                            ></time-panel>
                          </div>
                        </template>
                      </date-picker>
                    </div>
                    <div v-if="datetimeInvalid" class="date-error__alert">
                      <p min-width="240px" dense class="pb-0">
                        Invalid timestamp.
                      </p>
                    </div>
                  </div>
                  <div class="mr-4 edge-version">
                    <p class="mb-1 label-text">Version</p>
                    <v-text-field
                      placeholder="v1.0"
                      v-model="edgeVersion"
                      type="text"
                      outlined
                      dense
                      class="version-input"
                      :rules="rules.versionEdge"
                      :class="{ 'form-input': !formValid && !rowData.version }"
                      @focus="inputFocused"
                    ></v-text-field>
                  </div>
                </div>
                <div id="config">
                  <div class="values-text mr-1">
                    <div class="d-flex justify-space-between">
                      <p class="mb-1 label-text">Config</p>
                      <p class="preview-json" @click="formatJson('edge')">
                        Format
                      </p>
                    </div>
                    <v-textarea
                      v-model="edgeConfig"
                      outlined
                      no-resize
                      rows="14"
                      placeholder="{ }"
                      validate-on-blur
                      :rules="rules.valuesJson"
                      class="text-area-input"
                      :class="{ 'form-input': !formValid }"
                      @blur="valueInput($event, 'edge')"
                      @focus="handleFocusValues"
                    ></v-textarea>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-center">
                <v-alert v-model="formatJsonError" dismissible type="error"
                  >{{ errorMessage }}
                  <template v-slot:close>
                    <v-icon class="ml-1" color="white" @click="closeError"
                      >mdi-close-circle-outline</v-icon
                    >
                  </template>
                </v-alert>
              </div>
              <div id="edge-notes">
                <div class="notes-editor mb-8">
                  <div class="editor">
                    <p class="mb-1 label-text">Notes</p>
                    <v-md-editor
                      v-model="edgeNotes"
                      mode="edit"
                      height="250px"
                      left-toolbar="bold italic strikethrough ul ol link image"
                      @change="editNotes"
                    />
                  </div>
                </div>
              </div>
            </div>
            <section>
              <div class="d-flex justify-center mr-10 pb-2">
                <v-btn outlined width="7.5rem" class="mr-2" @click="cancel"
                  >Cancel</v-btn
                >
                <v-btn
                  width="7.5rem"
                  :disabled="
                    !formValid || formatJsonError || loadingCreateDeployment
                  "
                  color="var(--v-green-base)"
                  class="white--text"
                  @click="saveNewRow(tableType)"
                  @keyup.enter="saveNewRow(tableType)"
                  >Save
                  <div class="spinner-animation">
                    <v-progress-circular
                      v-if="loadingCreateDeployment"
                      size="25"
                      color="gray"
                      indeterminate
                    ></v-progress-circular></div
                ></v-btn>
              </div>
            </section>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
const { CalendarPanel, TimePanel } = DatePicker;

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  name: "EditDeploymentsTablesWidget",
  components: {
    DatePicker,
    CalendarPanel,
    TimePanel,
  },
  props: {
    tableType: {
      type: String,
      required: true,
      default: "server",
    },
    serverDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    edgeDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    createDeploymentLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    turbines: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      rowData: {
        version: "",
        expect_perf_change: false,
        values: null,
        notes: "",
      },
      perfChangeItems: [
        {
          text: "Yes",
          value: true,
        },
        { text: "No", value: false },
      ],
      /**
       * Initial values for the attributes of the markdown editor
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      selected: {},
      turbineSelected: {},
      rules: {},
      formValid: false,
      errorMessage: "",
      formatJsonError: false,
      date: null,
      datetimeInvalid: false,
      edgeVersion: "",
      edgeConfig: null,
      edgeNotes: "",
    };
  },
  computed: {
    ...mapState({
      serverDeployments: (state) => state.swarm.serverDeployments,
    }),
    loadingCreateDeployment() {
      return this.createDeploymentLoading;
    },
    title() {
      if (this.tableType === "server") return "Create Server deployment";
      else return "Create Edge deployment";
    },
    turbineSelections() {
      if (this.turbines.length > 0) {
        this.setInitialTurbine(this.turbines);
        return this.turbines;
      } else {
        return [];
      }
    },
    openDialog() {
      return this.serverDialog || this.edgeDialog;
    },
  },
  watch: {
    openDialog: {
      handler(value) {
        if (!value) {
          this.rules = {};
          this.$refs.deploymentForm.reset();
        } else {
          this.initialRowData();
          this.selected = { text: "False", value: false };
        }
      },
    },
  },
  methods: {
    initialRowData() {
      if (this.serverDeployments.length > 0) {
        const lastIndex = this.serverDeployments.length - 1;
        this.rowData.values = JSON.stringify(
          this.serverDeployments[lastIndex].values,
          null,
          2,
        );
        this.rowData.version = this.serverDeployments[lastIndex].version;
      }
    },
    editPerfChange(event) {
      this.rowData.expect_perf_change = event;
    },
    editValues(event, table) {
      if (table === "server") {
        this.rowData.values = event;
      } else if (table === "edge") {
        this.edgeConfig = event;
      }
    },
    editNotes(event, table) {
      if (table === "server") {
        this.rowData.notes = event;
      } else if (table === "edge") {
        this.edgeNotes = event;
      }
    },
    saveNewRow(table) {
      try {
        const siteId = +this.$route.params.siteId;
        let newRow = {
          site_id: siteId,
        };
        // Updating rules to show errors on submit rather than on blur
        this.rules = {
          versionServer: [
            (v) => !!v || "This field is required.",
            (v) =>
              /\d+\.\d+\.\d+/.test(v) ||
              "Version must contain at least 3 digits separated by decimals",
          ],
          versionEdge: [(v) => !!v || "This field is required."],
          valuesJson: [
            (v) => !!v || "Please be sure not to leave this field blank.",
            (v) => !Array.isArray(JSON.parse(v)) || "Must be a json object.",
          ],
          turbine: [
            (v) => v === "" || "Please select a turbine to proceed.",
            (v) => !!v || "Please select a turbine to proceed.",
          ],
        };

        if (table === "server") {
          newRow.version = this.rowData.version;
          newRow.expect_perf_change = this.rowData.expect_perf_change;
          newRow.values = this.rowData.values;
          newRow.notes = this.rowData.notes;
          if (this.rowData.values) {
            this.formatJson("server");
          }
        } else if (table === "edge") {
          newRow.turbine_id = this.turbineSelected?.turbine_id
            ? this.turbineSelected.turbine_id
            : this.turbineSelected;
          newRow.version = this.edgeVersion;
          newRow.config = this.edgeConfig;
          this.checkDateValidity();
          if (this.edgeNotes) {
            newRow.notes = this.edgeNotes;
          }
          if (this.edgeConfig) {
            this.formatJson("edge");
          }
          if (this.date && !this.datetimeInvalid) {
            newRow.ts = dayjs(newRow.ts).format("YYYY-MM-DD hh:mm:ss");
          } else {
            this.datetimeInvalid = true;
          }
        }
        // Wait for DOM to update, then check validity
        this.$nextTick(() => {
          if (
            this.$refs.deploymentForm.validate() &&
            !this.datetimeInvalid &&
            !this.formatJsonError
          ) {
            this.$emit("addNewRow", newRow, table);
            this.newRow = {};
          }
        });
      } catch (error) {
        this.formatJsonError = true;
        this.errorMessage = error.toString();
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    formatJson(table) {
      try {
        if (table === "server") {
          this.rowData.values = JSON.stringify(
            JSON.parse(this.rowData.values),
            null,
            2,
          );
        } else {
          this.edgeConfig = JSON.stringify(
            JSON.parse(this.edgeConfig),
            null,
            2,
          );
        }
      } catch (error) {
        console.error("ERROR:: ", error);
        this.formatJsonError = true;
        this.errorMessage = error.toString();
      }
    },
    handleFocusValues() {
      if (this.formatJsonError) {
        this.formatJsonError = false;
        this.errorMessage = "";
      }
      this.rules = {};
    },
    closeError() {
      if (this.formatJsonError) {
        this.formatJsonError = false;
        this.errorMessage = "";
      }
    },
    preventLetters(event) {
      if (
        event.key.match(/[a-zA-Z]/) &&
        event.code !== "Delete" &&
        event.code !== "Backspace" &&
        event.code !== "ArrowRight" &&
        event.code !== "ArrowLeft"
      ) {
        event.preventDefault();
      }
    },
    checkDateValidity() {
      if (!this.date || this.date >= new Date()) {
        this.datetimeInvalid = true;
      } else {
        this.datetimeInvalid = false;
      }
    },
    disabledDates(date) {
      return date >= new Date();
    },
    valueInput(event, table) {
      if (table === "server") {
        this.rowData.values = event.target._value;
      } else if (table === "edge") {
        this.edgeConfig = event.target._value;
      }
    },
    inputFocused() {
      if (this.turbineSelected) {
        this.rules = {};
        this.datetimeInvalid = false;
      }
    },
    setInitialTurbine(turbines) {
      this.turbineSelected = turbines[0];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
.form-container {
  max-width: 750px;
}

.first-col .v-input {
  width: 12rem;
}

.turbine-select,
.edge-version {
  width: 11rem;
}

.dialog-buttons {
  padding-bottom: 5px;
}

::v-deep {
  .values-text {
    min-width: 600px;
    font-family: monospace;
    .v-input__slot .v-text-field__slot textarea {
      line-height: 1rem;
      font-size: 0.875rem;
    }
  }
}

.label-text {
  color: var(--v-black1-base);
}

::v-deep {
  .form-input {
    .v-messages__message {
      color: var(--v-error-base);
    }
  }
}

::v-deep {
  .version-input .v-input__slot,
  .perf-change-input .v-input__slot,
  .text-area-input .v-input__slot {
    background-color: var(--v-textInputBackground-base);
  }
}

.preview-json {
  margin: 0;
  font-size: 0.75rem;
  color: var(--v-primary-base);
}

.preview-json:hover {
  cursor: pointer;
  text-decoration: underline;
}

.notes-editor {
  max-width: 675px;
  margin: auto;
}

.notes-input {
  width: 100%;
  flex-shrink: 1;
}

.edit-deployment-title {
  padding: 16px 24px 10px;
  background-color: var(--v-primaryBlue-base);
  height: 74px;
  h2 {
    color: var(--v-white1-base);
    font-size: 1.75rem;
    line-height: 1.5;
    font-family: "Museo Sans Rounded" !important;
    font-weight: 600;
  }
}

.edit-deployment-content {
  background-color: var(--v-white2-base);
}

.date-picker-container {
  position: relative;
}

.date-error__alert {
  position: absolute;
  top: 100%;
  z-index: 10;
}

::v-deep {
  .date-error {
    .mx-input {
      border-color: var(--v-error-base);
      border-width: 2px;
    }
  }
  .date-wrapper {
    .mx-datepicker {
      width: 11rem;
    }
    .mx-input {
      border-color: rgba(0, 0, 0, 0.42);
      height: 2.5rem !important;
    }
  }
}

.spinner-animation {
  position: absolute;
  left: 35%;
}
</style>
