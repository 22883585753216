var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.turbineData && this.turbineData.length === 0)?_c('DataLoading'):_c('div',[_c('section',{attrs:{"id":"controls"}},[_c('v-form',{model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{staticClass:"analytics-col min-length",attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"view-wrapper",class:{ 'error-highlight-view': !_vm.isViewValid }},[_c('v-autocomplete',{ref:"viewSelection",attrs:{"content-class":"pb-0","items":_vm.views,"label":"Select a view","hide-no-data":"","hide-selected":"","dense":"","solo":"","rules":_vm.selectedViewRules},on:{"change":_vm.changeViewSelected,"focus":function($event){return _vm.clearError('view')},"blur":_vm.handleBlurViewSelection},model:{value:(_vm.selectedView),callback:function ($$v) {_vm.selectedView=$$v},expression:"selectedView"}})],1)])]),(
              _vm.selectedView === 'yaw_misalignment' ||
              _vm.selectedView === 'minimum_pitch'
            )?_c('v-col',{staticClass:"analytics-col",attrs:{"cols":"4"}},[_c('div',{class:{ 'error-highlight-turbines': !_vm.isTurbinesValid }},[_c('v-combobox',{ref:"turbineCombobox",attrs:{"items":_vm.turbineOptions,"label":"Turbine selection","placeholder":"Turbine selection","multiple":"","clearable":"","solo":"","dense":"","rules":_vm.selectedTurbinesRules},on:{"focus":function($event){return _vm.clearError('turbines')},"blur":_vm.callAnalyticsAPI},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_c('div',{staticClass:"d-flex no-wrap"},[(!_vm.turbinesOnly && index === 0)?_c('span',[_vm._v("All turbines")]):(_vm.turbinesOnly)?_c('span',[_vm._v(_vm._s(item.text)),(index !== _vm.selectedTurbines.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()]):_vm._e()])]}}],null,false,307348067),model:{value:(_vm.selectedTurbines),callback:function ($$v) {_vm.selectedTurbines=$$v},expression:"selectedTurbines"}})],1)]):_vm._e()],1),(_vm.selectedView !== 'mod_window_suitability')?_c('div',{staticClass:"d-flex mt-5 align-center"},[_c('div',{staticClass:"d-flex flex-wrap align-center error-anchor"},[_c('div',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.dateError))]),_c('div',{staticClass:"d-flex mr-7 date-picker"},[_c('div',{staticClass:"mr-7"},[_c('date-picker',{class:{
                    'error-highlight': !_vm.isStartDateValid,
                  },attrs:{"type":"date","placeholder":"Start date","clearable":false},on:{"focus":function($event){return _vm.clearError('date')}},scopedSlots:_vm._u([{key:"content",fn:function(slotProps){return [_c('calendar-panel',{attrs:{"disabled-date":function (date) { return date > _vm.dateRange.endDate; },"value":slotProps.value},on:{"select":slotProps.emit}})]}}],null,false,567469673),model:{value:(_vm.dateRange.startDate),callback:function ($$v) {_vm.$set(_vm.dateRange, "startDate", $$v)},expression:"dateRange.startDate"}})],1),_c('div',[_c('date-picker',{staticClass:"mr-2",class:{
                    'error-highlight': !_vm.isEndDateValid,
                  },attrs:{"type":"date","placeholder":"End date","clearable":false},on:{"focus":function($event){return _vm.clearError('date')}},scopedSlots:_vm._u([{key:"content",fn:function(slotProps){return [_c('calendar-panel',{attrs:{"disabled-date":function (date) { return date > new Date(); },"value":slotProps.value},on:{"select":slotProps.emit}})]}}],null,false,3550863230),model:{value:(_vm.dateRange.endDate),callback:function ($$v) {_vm.$set(_vm.dateRange, "endDate", $$v)},expression:"dateRange.endDate"}})],1)]),(this.selectedView === 'yaw_misalignment')?_c('div',{staticClass:"yaw-adjustments-switch"},[_c('label',{staticClass:"mr-2 mb-0"},[_vm._v("Show adjustments")]),_c('v-switch',{model:{value:(_vm.showAdjustments),callback:function ($$v) {_vm.showAdjustments=$$v},expression:"showAdjustments"}})],1):_vm._e()])]):_vm._e()],1)],1),(_vm.imageLoading)?_c('div',{staticClass:"mt-10"},[_c('DataLoading')],1):_c('section',{staticClass:"mt-9",attrs:{"id":"display"}},[_c('v-card',[_c('div',{staticClass:"analytics-display"},[(_vm.analyticsFigure)?_c('div',[_c('Plotly',{ref:"plotlyDiagram",attrs:{"id":"plotly_diagram","data":_vm.yawMisalignmentFigureData,"layout":_vm.analyticsFigure.figure.layout}})],1):_c('div',{staticClass:"no-data-backdrop no-data-available"},[_vm._v(" NO DATA AVAILABLE ")])])]),(_vm.analyticsFigure)?_c('div',{staticClass:"pb-5 d-flex pt-6 request-btn-wrapper"},[_c('div',{staticClass:"pointer"},[_c('v-menu',{attrs:{"open-delay":_vm.popupDelay,"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"info-icon",attrs:{"size":"1.25rem"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.informationIcon))])]}}],null,false,707141980)},[_c('v-card',[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.analyticsFigure.figure_description)+" ")])],1)],1)],1)]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }