<template>
  <v-card
    outlined
    class="summary-widget h100"
    v-bind:class="{
      'blue-background': isEditPP,
      'summary-widget': !isEditPP,
      'edit-widget': isEditPP,
    }"
  >
    <div
      v-if="loading || powerPriceLoading"
      class="d-flex align-center justify-center h100"
    >
      <v-progress-circular
        :size="summaryWidgetSpinnerSize"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div id="edit-info-card" v-else-if="isEditPP">
      <div class="d-flex justify-space-between align-center">
        <p class="widget-title widget-header">Edit power price<span></span></p>
        <div>
          <v-icon class="close-icon mr-1" size="1.15rem" @click="cancelEditPP"
            >mdi-close</v-icon
          >
          <v-tooltip top open-delay="100">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                size="1.05rem"
                class="save-icon"
                @click="savePriceAndDate"
                >mdi-check-bold</v-icon
              >
            </template>
            <span>Save</span>
          </v-tooltip>
        </div>
      </div>
      <div :class="textFieldClass">
        <v-text-field
          v-model="price"
          hide-details
          class="mr-3 mt-2 pa-0"
          @focus="textFieldFocused()"
          @blur="textFieldBlurred()"
          @keypress.native="preventDecimals($event)"
          height="25px"
          type="number"
          prepend-inner-icon="mdi-currency-usd"
          placeholder="0"
        >
        </v-text-field>
      </div>
      <p class="widget-title widget-footer mt-3">
        <date-picker
          type="date"
          v-model="date"
          class="mr-2 date-picker"
          :class="{ 'date-error': !dateValid }"
          @focus="dateFocused"
        >
          <template v-slot:content="slotProps">
            <calendar-panel
              :disabled-date="(date) => date > new Date()"
              :value="slotProps.value"
              @select="slotProps.emit"
            ></calendar-panel>
          </template>
        </date-picker>
      </p>
      <div v-if="!priceValid && dateValid" class="date-error__alert">
        <v-alert min-width="240px" dense type="error">Invalid price.</v-alert>
      </div>
      <div v-else-if="!dateValid && priceValid" class="date-error__alert">
        <v-alert min-width="240px" dense type="error">Invalid date.</v-alert>
      </div>
      <div v-else-if="!dateValid && !priceValid" class="date-error__alert">
        <v-alert min-width="240px" dense type="error"
          >Invalid price and date.</v-alert
        >
      </div>
    </div>
    <div v-else class="d-flex flex-column justify-space-between h100">
      <div class="d-flex justify-space-between">
        <p class="widget-title widget-header">
          {{ data.powerPrice.title }}
        </p>
        <v-tooltip top open-delay="150">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="0.85rem"
              class="edit-icon"
              @click="editPowerPrice"
              v-if="hasWriteAccess"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Edit power price</span>
        </v-tooltip>
      </div>
      <h3 class="ma-0 mt-3 mb-3">${{ powerPrice }}/MWh</h3>
      <p class="widget-title widget-footer flex-wrap pt-1">
        <span>Updated {{ data.powerPrice.updatedAt }}</span>
      </p>
    </div>
  </v-card>
</template>

<script>
import DatePicker from "vue2-datepicker";
const { CalendarPanel } = DatePicker;
import { DateTime } from "luxon";
import { roundToString } from "@/helpers/functions";
import { summaryWidgetSpinnerSize } from "@/helpers/variables";

export default {
  name: "PowerPriceCard",
  components: {
    DatePicker,
    CalendarPanel,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasWriteAccess: {
      type: Boolean,
      required: false,
      default: false,
    },
    powerPriceLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isEditPP: false,
      date: null,
      price: 0,
      dateValid: true,
      priceValid: true,
      textFieldClass: "d-flex align-center justify-center currency-input",
      summaryWidgetSpinnerSize,
    };
  },
  computed: {
    powerPrice() {
      return this.data?.powerPrice?.usd;
    },
  },
  methods: {
    editPowerPrice() {
      this.price = this.data.powerPrice.usd;
      this.isEditPP = true;
    },
    cancelEditPP() {
      this.isEditPP = false;
      this.price = this.data?.powerPrice?.usd;
      this.date = null;
    },
    preventDecimals(event) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = event.key;

      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault();
      }
    },
    savePriceAndDate() {
      const siteId = this.$route.params.siteId;
      let powerPriceObj = {
        siteId: siteId,
      };

      if (this.price > 0 && this.price !== this.data.powerPrice.usd) {
        this.priceValid = true;
      } else {
        this.priceValid = false;
        this.textFieldClass = "d-flex align-center justify-center price-error";
      }
      if (this.date > new Date()) {
        this.dateValid = false;
      } else {
        this.dateValid = true;
      }
      if (this.priceValid && this.dateValid) {
        let priceToSend = parseFloat(this.price);
        priceToSend = roundToString(priceToSend, 0);
        const temp = DateTime.fromJSDate(this.date);
        const utc = temp.setZone("UTC");
        const dateToSend = utc.toFormat("yyyy-LL-dd");
        powerPriceObj.price = priceToSend;
        powerPriceObj.date = dateToSend;

        this.$emit("editPowerPrice", powerPriceObj);
        this.isEditPP = false;
        this.price = this.data?.powerPrice?.usd;
        this.date = null;
      }
    },
    textFieldFocused() {
      this.priceValid = true;
      this.textFieldClass =
        "d-flex align-center justify-center focused-price-input";
    },
    textFieldBlurred() {
      this.textFieldClass = "d-flex align-center justify-center currency-input";
    },
    dateFocused() {
      this.dateValid = true;
    },
  },
};
</script>
